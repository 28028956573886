import { useWeb3React } from '@web3-react/core'
import info from 'assets/svg/info.svg'
import syncimage from 'assets/svg/synergy.svg'
import bgGridImage from 'assets/svg/linear-bg-berachain-grid.svg'
import BigNumber from 'bignumber.js'
import { MouseoverTooltip, TooltipSize } from 'components/Tooltip'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { POINTS_API_URLS } from '../../constants/exchange'

interface PointResponse {
  total: {
    earned: string
    pending: string
  }
  trading: {
    earned: string
    pending: string
  }
  lp: {
    earned: string
    pending: string
  }
}

function formatPoint(value: string) {
  const number = new BigNumber(value)

  if (number.gte(1e9)) {
    const res = number.dividedBy(1e9).toNumber()

    return `${formatDisplay(res, { decimalToShow: 2 })}B`
  }

  return formatDisplay(number.toNumber(), { decimalToShow: 0 })
}

async function fetcher({ queryKey }: { queryKey: any }) {
  const [, chainId, account] = queryKey
  const apiUrl = chainId ? POINTS_API_URLS[chainId] : undefined
  if (!apiUrl || !account) {
    return undefined
  }

  const url = `${apiUrl}/users?address=${account}`

  const res = await fetch(url)
  const data = await res.json()

  return data
}

function useGetPoint(chainId: number | undefined) {
  const { account } = useWeb3React()

  const data = useQuery<PointResponse>(['user-point', chainId, account], fetcher, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}

export function Point() {
  const { chainId } = useWeb3React()

  const [isVisible, setIsVisible] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const { data } = useGetPoint(chainId)

  const point = useMemo(() => {
    if (!data) {
      return {
        total: '--',
        pending: '--',
      }
    }
    // const total = new BigNumber(data.total.earned).toNumber()
    const pending = new BigNumber(data.total.pending).toNumber()

    return {
      total: formatPoint(data.total.earned),
      pending: formatDisplay(pending, { decimalToShow: 0 }),
    }
  }, [data])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    function onClick(e: MouseEvent) {
      if (!e.target || !containerRef.current) {
        return
      }

      if (!containerRef.current.contains(e.target as any)) {
        setIsVisible(false)
      }
    }

    document.addEventListener('click', onClick)

    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [])

  if (!chainId || !POINTS_API_URLS[chainId]) {
    return null
  }

  return (
    <div className="relative" ref={containerRef}>
      <div
        onClick={() => {
          return setIsVisible((v) => !v)
        }}
        className="cursor-pointer flex items-center gap-2 border border-[#242424] rounded-full h-[44px] pl-[8px] pr-[12px] mr-[12px]"
      >
        <img src={syncimage} alt="Synergy" width={28} height={28} loading="lazy" />
        <p className="text-base">{point.total}</p>
      </div>

      {isVisible && (
        <div className="absolute -bottom-[16px] translate-y-full min-w-[215px] w-max right-0 md:right-auto md:left-0 linear-bg-berachain">
          <div
            className="bg-no-repeat bg-left-top bg-cover"
            style={{
              backgroundImage: `url(${bgGridImage})`,
            }}
          >
            <div className="p-[16px]">
              <p className="text-xs text-white/60 mb-3">Your Point Earned</p>

              <div className="flex items-center gap-2">
                <img src={syncimage} alt="Synergy" width={28} height={28} loading="lazy" />

                <p className="text-[24px] font-medium leading-[32px]">{point.total}</p>
              </div>
            </div>

            <div className="w-full h-[1px] bg-[#FFA800]" />

            <div className="p-[16px]">
              <div className="flex items-center gap-2 mb-3">
                <p className="text-xs text-white/60">Estimate Point</p>
                <MouseoverTooltip
                  text={
                    <p>
                      This is an estimated point and will be <br /> finalized at the end of the epoch.
                    </p>
                  }
                  placement="bottom"
                  size={TooltipSize.Max}
                  className="[&_.tooltip-content]:!bg-[#FFA800] [&_.tooltip-content]:!text-black [&_.popper-arrow:before]:!bg-[#FFA800]"
                >
                  <img src={info} alt="info" width={16} height={16} loading="lazy" className="cursor-pointer" />
                </MouseoverTooltip>
              </div>

              <div className="flex items-center gap-2">
                <img src={syncimage} alt="Synergy" width={28} height={28} loading="lazy" />

                <p className="text-[24px] font-medium leading-[32px]">{point.pending}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
