import { devtools } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface PriceParams {
  priceHold: string
  setPriceHold: (data: string) => void
}

export const usePrice = createWithEqualityFn<PriceParams>()(
  devtools(
    (set) => ({
      priceHold: '0',
      setPriceHold: (data) => set(() => ({ priceHold: data })),
    }),
    { name: 'usePrice' }
  ),
  shallow
)

interface WalletParams {
  proxyWallet: boolean
  open: boolean
}

export const useWallet = createWithEqualityFn<WalletParams>()(
  devtools(
    (set) => ({
      proxyWallet: false,
      open: false,
    }),
    { name: 'useWallet' }
  ),
  shallow
)
