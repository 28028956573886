import { useWeb3React } from '@web3-react/core'
import { getApolloClientByChain } from 'pages/Pool/Overview/client'
import { fetchPoolChartData } from 'pages/Pool/Overview/data/chartData'
import { PoolChartEntry } from 'pages/Pool/Overview/data/derived'
import { useEffect, useState } from 'react'

/**
 * Get top pools addresses that token is included in
 * If not loaded, fetch and store
 * @param address
 */
export function usePoolChartData(address: string): PoolChartEntry[] | undefined {
  const { chainId } = useWeb3React()

  const [chartData, setData] = useState<PoolChartEntry[] | undefined>(undefined)

  const [error, setError] = useState(false)

  useEffect(() => {
    const client = getApolloClientByChain(chainId)

    async function fetch() {
      const { error, data } = await fetchPoolChartData(address, client)
      if (!error && data) {
        // dispatch(updatePoolChartData({ poolAddress: address, chartData: data, networkId: activeNetwork.id }))
        setData(data)
      }
      if (error) {
        setError(error)
      }
    }

    if (!chartData && !error && client) {
      fetch()
    }
  }, [address, error, chartData, chainId])

  // return data
  return chartData
}
