import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'
import { PAYMASTER_LIST } from 'state/paymaster/initStore'
import { setPaymasterTokenList, setPaymasterTokenSelect } from 'state/paymaster/slices'

export function useInitPaymaster() {
  const dispatch = useAppDispatch()

  const { chainId } = useWeb3React()

  useEffect(() => {
    function init() {
      if (!chainId) {
        return
      }

      if (!PAYMASTER_LIST?.[chainId]) {
        return
      }
      dispatch(setPaymasterTokenSelect(PAYMASTER_LIST[chainId].tokens[0]))
      dispatch(setPaymasterTokenList(PAYMASTER_LIST[chainId].tokens))
    }

    init()
  }, [chainId, dispatch])
}
