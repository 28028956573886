import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { EstimateGas } from 'components/EstimatedGas/EstimatedGas'
import { useEstimatedGasSwap } from 'hooks/useEstimatedGasSwap'
import { Allowance, AllowanceState } from 'hooks/usePermit2Allowance'
import { useMemo } from 'react'
import { isClassicTrade } from 'state/routing/utils'
import { useSwapContext } from 'state/swap/hooks'

type Props = {
  allowance: Allowance
}

export const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export function SwapEstimateGas({ allowance }: Props) {
  const { chainId } = useWeb3React()

  const {
    derivedSwapInfo: {
      trade: { trade },
      allowedSlippage,
      inputError,
    },
  } = useSwapContext()

  const { gasCost, gasError } = useEstimatedGasSwap(
    isClassicTrade(trade) ? trade : undefined,
    {
      slippageTolerance: allowedSlippage,
    },
    allowance.state === AllowanceState.REQUIRED
  )
  const isDisplay = useMemo(() => {
    if (inputError) {
      return false
    }

    return !!trade
  }, [inputError, trade])

  const isZKsync = useMemo(() => chainId === ChainId.ZKSYNC, [chainId])

  if (!isZKsync) {
    return null
  }

  return <EstimateGas gasCost={gasCost} gasError={gasError} isDisplay={isDisplay} />
}
