import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useQuery } from 'react-query'
import { BERA_OFT, ZKSYNC_OFT, oftAbi } from '../constant'

async function fetcher(chainId?: number, provider?: ethers.providers.Web3Provider) {
  if (!provider || !chainId) {
    return undefined
  }

  let oft = ZKSYNC_OFT.oft
  // zk_sepolia
  if (chainId === BERA_OFT.chainId) {
    oft = BERA_OFT.oft
  }

  const oftContract = new ethers.Contract(oft, oftAbi, provider)
  const feeF = await oftContract.feeBps()

  return feeF.toNumber()
}
export function useFee() {
  const { provider, chainId } = useWeb3React()

  const resp = useQuery(['fee-bridge', chainId], () => fetcher(chainId, provider), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return {
    ...resp,
    data: resp?.data ? resp.data / 10000 : 0,
  }
}
