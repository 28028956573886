import close from 'assets/svg/closev2.svg'
import error from 'assets/svg/error.svg'
import success from 'assets/svg/success.svg'
import { Link } from 'react-router-dom'
import { ExternalToast, toast } from 'sonner'

export function toastSuccess(props: { title: string; txh?: string }, data?: ExternalToast) {
  const { title, txh } = props

  return toast(
    () => {
      return (
        <div>
          <div className="flex items-center gap-3">
            <img src={success} alt="success" className="w-7 h-7" />

            <div className="flex-1 flex flex-col gap-2">
              <p className="text-white text-base font-medium">{title}</p>

              {txh && (
                <Link to={txh} target="_blank">
                  <p className="text-sm text-white underline">View transaction</p>
                </Link>
              )}
            </div>

            <button onClick={() => toast.dismiss()}>
              <img src={close} alt="close" width={16} height={16} loading="lazy" />
            </button>
          </div>
        </div>
      )
    },
    {
      ...data,
      className: '[&>div]:w-full',
    }
  )
}

export function toastError(title: string, data?: ExternalToast) {
  return toast(
    () => {
      return (
        <div className="flex items-center gap-3">
          <img src={error} alt="error" className="w-7 h-7" />

          <div className="flex-1">
            <p className="text-white text-base font-medium">{title}</p>
          </div>

          <button onClick={() => toast.dismiss()}>
            <img src={close} alt="close" width={16} height={16} loading="lazy" />
          </button>
        </div>
      )
    },
    {
      ...data,
      className: '[&>div]:w-full',
    }
  )
}
