import iconDropWhite from 'assets/svg/icon-drop-white.svg'
import clsx from 'clsx'
import { useGetTokens } from 'components/AccountDrawer/MiniPortfolio/NewTokens/data'
import Loading from 'components/Loading/Loading'
import { ethers } from 'ethers'
import { useGetRate } from 'hooks/useGetRateTokens'
import { useInitPaymaster } from 'pages/bridge/hook/useInitPaymaster'
import { useMemo, useState } from 'react'
import { useAppSelector } from 'state/hooks'
import { AppState } from 'state/reducer'
import { formatDisplay } from 'utils/formatNumbers'
import ModalSwitchTokenGas from './ModalSwitchTokenGas/ModalSwitchTokenGas'

export const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

type Props = {
  className?: string
  gasCost: ethers.BigNumber
  gasError: boolean
  isDisplay: boolean
}

export function EstimateGas({ className, gasCost, gasError, isDisplay }: Props) {
  const { data } = useGetTokens()
  useInitPaymaster()
  const paymasterTokenSelect = useAppSelector((state: AppState) => state.paymasterStoreReducer.paymasterTokenSelect)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const loading = gasCost.eq('0')

  const rateList = useGetRate()
  const gasCostInUsd = useMemo(() => {
    if (!data) {
      return {
        gasUsd: '0',
        gasAmount: '0',
      }
    }
    const quote_rate_native = data.find((item) => item.contract_address.toLowerCase() === NATIVE_ADDRESS)?.quote_rate

    if (paymasterTokenSelect.address === NATIVE_ADDRESS) {
      const gas = Number(parseFloat(ethers.utils.formatEther(gasCost)) * Number(quote_rate_native))

      const gasAmount = gas / Number(quote_rate_native)
      return {
        gasUsd: formatDisplay(Number(gas), {
          decimalToShow: paymasterTokenSelect.decimalsToShow,
        }),
        gasAmount: formatDisplay(gasAmount, { decimalToShow: paymasterTokenSelect.decimalsToShow }),
      }
    }

    if (!rateList) {
      return {
        gasUsd: '--',
        gasAmount: '--',
      }
    }

    const gasAmount =
      parseFloat(ethers.utils.formatEther(gasCost)) * rateList[paymasterTokenSelect.address.toLowerCase()].after

    const discount =
      rateList[paymasterTokenSelect.address.toLowerCase()].after /
      rateList[paymasterTokenSelect.address.toLowerCase()].before

    const gas = Number(parseFloat(ethers.utils.formatEther(gasCost)) * Number(quote_rate_native)) * (discount || 0)
    return {
      gasUsd: formatDisplay(Number(gas), { decimalToShow: paymasterTokenSelect.decimalsToShow }),
      gasAmount: formatDisplay(gasAmount, { decimalToShow: paymasterTokenSelect.decimalsToShow }),
    }
  }, [data, paymasterTokenSelect.address, paymasterTokenSelect.decimalsToShow, rateList, gasCost])

  const handleClose = () => {
    setIsOpenModal(false)
  }

  const handleOpen = () => {
    setIsOpenModal(true)
  }

  if (!isDisplay) {
    return <></>
  }

  return (
    <div className={clsx(className, 'hidden rounded-[16px] px-4 md:flex flex-col justify-center gap-[12px]')}>
      <div className="flex justify-between items-center">
        <p className="text-sm text-[#808080]">Estimated Gas</p>
        {gasError ? (
          <p className="text-sm text-[#808080]">Estimated gas failed</p>
        ) : (
          <Loading height={16} width={80} isLoading={loading}>
            <button onClick={handleOpen} className="flex items-center bg-[#141414] rounded-full px-3 py-2 text-sm">
              ${gasCostInUsd.gasUsd} ~ {gasCostInUsd.gasAmount}
              <img src={paymasterTokenSelect.imageUrl} className="w-4 h-4 ml-2" alt="" />
              <img
                src={iconDropWhite}
                className={clsx('w-4 h-4 ml-1 transition-all', {
                  'rotate-180': isOpenModal,
                })}
                alt=""
              />
            </button>
          </Loading>
        )}

        <ModalSwitchTokenGas gasCost={gasCost} isOpen={isOpenModal} onClose={handleClose} />
      </div>
    </div>
  )
}

export function EstimateGasBridge({ className, gasCost, gasError, isDisplay }: Props) {
  const { data } = useGetTokens()
  useInitPaymaster()
  const paymasterTokenSelect = useAppSelector((state: AppState) => state.paymasterStoreReducer.paymasterTokenSelect)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const loading = gasCost.eq('0')

  const rateList = useGetRate()
  const gasCostInUsd = useMemo(() => {
    if (!data) {
      return {
        gasUsd: '0',
        gasAmount: formatDisplay(Number(ethers.utils.formatEther(gasCost)), {
          decimalToShow: paymasterTokenSelect.decimalsToShow,
        }),
      }
    }
    const quote_rate_native = data.find((item) => item.contract_address.toLowerCase() === NATIVE_ADDRESS)?.quote_rate

    if (paymasterTokenSelect.address === NATIVE_ADDRESS) {
      const gas = Number(parseFloat(ethers.utils.formatEther(gasCost)) * Number(quote_rate_native))

      return {
        gasUsd: formatDisplay(Number(gas), {
          decimalToShow: paymasterTokenSelect.decimalsToShow,
        }),
        gasAmount: formatDisplay(Number(ethers.utils.formatEther(gasCost)), {
          decimalToShow: paymasterTokenSelect.decimalsToShow,
        }),
      }
    }

    if (!rateList) {
      return {
        gasUsd: '--',
        gasAmount: '--',
      }
    }

    const gasAmount =
      parseFloat(ethers.utils.formatEther(gasCost)) * rateList[paymasterTokenSelect.address.toLowerCase()].after

    const discount =
      rateList[paymasterTokenSelect.address.toLowerCase()].after /
      rateList[paymasterTokenSelect.address.toLowerCase()].before

    const gas = Number(parseFloat(ethers.utils.formatEther(gasCost)) * Number(quote_rate_native)) * (discount || 0)
    return {
      gasUsd: formatDisplay(Number(gas), { decimalToShow: paymasterTokenSelect.decimalsToShow }),
      gasAmount: formatDisplay(gasAmount, { decimalToShow: paymasterTokenSelect.decimalsToShow }),
    }
  }, [data, paymasterTokenSelect.address, paymasterTokenSelect.decimalsToShow, rateList, gasCost])

  const handleClose = () => {
    setIsOpenModal(false)
  }

  const handleOpen = () => {
    if (!data) {
      return
    }
    setIsOpenModal(true)
  }

  if (!isDisplay) {
    return <></>
  }

  return (
    <div className={clsx('hidden rounded-[16px] md:flex flex-col justify-center gap-[12px]', className)}>
      <div className="flex justify-between items-center">
        <p className="text-sm text-[#808080]">Estimated Gas</p>
        {gasError ? (
          <p className="text-sm text-[#808080]">Estimated gas failed</p>
        ) : (
          <Loading height={16} width={80} isLoading={loading}>
            <button onClick={handleOpen} className="flex items-center bg-[#141414] rounded-full px-3 py-2 text-sm">
              ${gasCostInUsd.gasUsd} ~ {gasCostInUsd.gasAmount}
              <img src={paymasterTokenSelect.imageUrl} className="w-4 h-4 ml-2" alt="" />
              <img
                src={iconDropWhite}
                className={clsx('w-4 h-4 ml-1 transition-all', {
                  'rotate-180': isOpenModal,
                })}
                alt=""
              />
            </button>
          </Loading>
        )}

        <ModalSwitchTokenGas gasCost={gasCost} isOpen={isOpenModal} onClose={handleClose} />
      </div>
    </div>
  )
}
