import { useWeb3React } from '@web3-react/core'
import clsx from 'clsx'
import { useMemo } from 'react'
import { SwapRouting } from '../../components/swap/SwapRouting/SwapRouting'
import { POINTS_API_URLS } from '../../constants/exchange'
import { InterfaceTrade } from '../../state/routing/types'
import { SwapEstimatePoint } from './SwapEstimate/EstimatePoint'

interface Props {
  trade: InterfaceTrade
}

export default function SwapMoreInfo({ trade }: Props) {
  const { chainId } = useWeb3React()

  const supportedPoint = useMemo(() => (chainId ? POINTS_API_URLS[chainId] : undefined), [chainId])

  return (
    <div
      className={clsx(
        'px-[24px] rounded-[16px] flex-col gap-[12px] bg-[#0D0D0D] py-[16px]',
        supportedPoint ? 'flex' : 'hidden md:flex'
      )}
    >
      {supportedPoint ? <SwapEstimatePoint /> : null}

      <div className="hidden md:flex">
        <SwapRouting trade={trade as any} />
      </div>
    </div>
  )
}
