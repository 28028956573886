import { useCallback, useEffect, useRef, useState } from 'react'
import { Subsriber } from './SubscriptionManager'
import { getWebSocket } from './WebsocketGlobal'

export enum ReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

const useWebSocket = (url: string) => {
  const socket = useRef<ReturnType<typeof getWebSocket> | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [readyState, setReadyState] = useState<ReadyState>(ReadyState.CLOSED)
  const [isAuthen, setAuthen] = useState<boolean | undefined>(false)

  const sendMessage = useCallback(
    (event: any) => {
      socket.current = getWebSocket(url)
      if (socket.current && socket.current.readyState === WebSocket.OPEN) {
        const message = JSON.stringify(event)
        socket.current.send(message)
      } else {
        console.error('WebSocket is not open. Unable to send message.')
      }
    },
    [url]
  )

  const logOut = useCallback(() => {
    if (socket.current) {
      // call socket close => onClose(close socket) => socket retry connect => connected => ... subscribe
      socket.current.close()
      // setAuthen(false);
    } else {
      console.error('WebSocket is not open. Unable to send message.')
    }
  }, [])

  useEffect(() => {
    const connect = () => {
      const ws = getWebSocket(url)
      socket.current = ws
      setAuthen(ws.isAuthen)
    }

    const subscribe = (val: { type: string; readyState: ReadyState; error: Error; isAuthen: boolean }) => {
      if (val.type === 'readyState') setReadyState(val.readyState)
      if (val.type === 'error') setError(val.error?.message ?? 'WebSocket error: ')
      setAuthen(val.isAuthen)
    }

    const unsubscribe = Subsriber.subscribe(url, subscribe)

    connect()
    return () => {
      unsubscribe()
    }
  }, [url])

  return {
    socket,
    readyState,
    sendMessage,
    isAuthen,
    error,
    Subsriber,
    logOut,
  }
}

export default useWebSocket
