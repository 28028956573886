import BigNumber from 'bignumber.js'
import useWebSocket, { ReadyState } from 'components/context/useWebsocket'
import { memo, useEffect, useMemo } from 'react'
import { formatDisplay } from 'utils/formatNumbers'
import { usePrice } from './store'

const WS_PRICE = 'wss://kline.hold.so/ws'
const POOL_ADDRESS = '0x9bec30fd825f5e8e9bc6a84914b8a3ab31742103'

function SubcribePrice() {
  const { readyState, sendMessage, Subsriber } = useWebSocket(WS_PRICE)

  useEffect(() => {
    const unsubPrice = Subsriber.subscribe('price', (event) => {
      usePrice.getState().setPriceHold(event?.payload?.p || '0')
    })

    const unsubPosition = Subsriber.subscribe('message', (event) => {
      if (event?.data?.type !== 'price') {
        return
      }
      usePrice.getState().setPriceHold(event?.data?.payload?.p || '0')
    })

    return () => {
      unsubPosition()
      unsubPrice()
    }
  }, [Subsriber])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage({
        id: 123,
        type: 'subscribe',
        params: [POOL_ADDRESS],
      })
    }
  }, [readyState, sendMessage])

  return null
}

export const Price = memo(function Price({ value }: { value: string }) {
  const price = usePrice((state) => state.priceHold)

  const formated = useMemo(() => {
    if (!value || price === '0') {
      return '--'
    }

    const total = new BigNumber(price).multipliedBy(value).toNumber()

    return formatDisplay(total, { decimalToShow: 2 })
  }, [price, value])

  return (
    <>
      <SubcribePrice />

      <p className="text-[#808080] text-xs font-medium leading-[18px]">~ ${formated}</p>
    </>
  )
})
