import NavBar from 'components/NavBar'
import { memo } from 'react'

export const Header = memo(function Header() {
  return (
    <div className="sticky top-0 bg-black z-[1020]">
      <NavBar />
    </div>
  )
})
