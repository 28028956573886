import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'
import { DEFAULT_CHAIN_ID } from '../../../constants/chains'
import { CHAIN_SUBGRAPH_URL } from '../../../graphql/thegraph/apollo'

const CHAIN_BLOCK_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.ZKSYNC]: 'https://subgraph.hold.so/subgraphs/name/blocklytics/zksync-core-blocks',
  [ChainId.BERACHAIN_BARTIO]: 'https://subgraph-bera.lab-test.xyz/subgraphs/name/blocklytics/ethereum-blocks',
  [ChainId.BERACHAIN]: 'https://subgraph.hold.so/bera/subgraphs/name/blocklytics/berachain-blocks',
}

export function getBlockClientByChain(chainId: number | undefined): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: (chainId && CHAIN_BLOCK_SUBGRAPH_URL[chainId]) || CHAIN_BLOCK_SUBGRAPH_URL[DEFAULT_CHAIN_ID],
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
}

export function getApolloClientByChain(chainId: number | undefined): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: (chainId && CHAIN_SUBGRAPH_URL[chainId]) || CHAIN_SUBGRAPH_URL[DEFAULT_CHAIN_ID],
    cache: new InMemoryCache({
      typePolicies: {
        Token: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
        Pool: {
          // Singleton types that have no identifying field can use an empty
          // array for their keyFields.
          keyFields: false,
        },
      },
    }),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
}
