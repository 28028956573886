import { ChainId, Currency, NativeCurrency, Token, WETH9 } from '@uniswap/sdk-core'
import invariant from 'tiny-invariant'

// eslint-disable-next-line no-restricted-syntax
export const NATIVE_CHAIN_ID = 'NATIVE'

export const USDC_MAINNET = new Token(
  ChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)

export const USDC_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
  6,
  'USDC',
  'USD//C'
)

export const USDC_ARBITRUM = new Token(
  ChainId.ARBITRUM_ONE,
  '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  6,
  'USDC',
  'USD//C'
)

export const USDC_POLYGON = new Token(
  ChainId.POLYGON,
  '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
  6,
  'USDC',
  'USD Coin'
)

export const USDC_BASE = new Token(ChainId.BASE, '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', 6, 'USDC', 'USD Coin')

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const DAI_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin'
)

export const MATIC_MAINNET = new Token(
  ChainId.MAINNET,
  '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
  18,
  'MATIC',
  'Polygon Matic'
)

export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')

export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')

export const WETH_POLYGON = new Token(
  ChainId.POLYGON,
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  18,
  'WETH',
  'Wrapped Ether'
)
const CELO_CELO = new Token(ChainId.CELO, '0x471EcE3750Da237f93B8E339c536989b8978a438', 18, 'CELO', 'Celo')

const CELO_CELO_ALFAJORES = new Token(
  ChainId.CELO_ALFAJORES,
  '0xF194afDf50B03e69Bd7D057c1Aa9e10c9954E4C9',
  18,
  'CELO',
  'Celo'
)

// export const USDC_ZKSYNC = new Token(
//   ChainId.ZKSYNC,
//   '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
//   6,
//   'USDC',
//   'USD Coin (Native)'
// )

export const USDCe_ZKSYNC = new Token(
  ChainId.ZKSYNC,
  '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  6,
  'USDC.e',
  'USD Coin (Ethereum)'
)

export const USDC_ZKSYNC = new Token(ChainId.ZKSYNC, '0x1d17cbcf0d6d143135ae902365d2e5e2a16538d4', 6, 'USDC', 'USDC')

export const tUSDC_BERACHAIN_BARTIO = new Token(
  ChainId.BERACHAIN_BARTIO,
  '0x123C070bFC0267Ea7905CFD691dDC78963443D86',
  6,
  'USDC',
  'USDC (Testnet)'
)

export const USDT_ZKSYNC = new Token(
  ChainId.ZKSYNC,
  '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
  6,
  'USDT',
  'Tether USD'
)

export const HOLD_ZKSYNC = new Token(
  ChainId.ZKSYNC,
  '0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2',
  18,
  'HOLD',
  'Holdstation'
)

export const ZK_ZKSYNC = new Token(ChainId.ZKSYNC, '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E', 18, 'ZK', 'zkSync')

export const KZ_ZKSYNC = new Token(ChainId.ZKSYNC, '0x5FD37F12029511F17C1898477f7fb685cF6C0C0f', 18, 'KZ', 'KZ')

export const WBTC_ZKSYNC = new Token(
  ChainId.ZKSYNC,
  '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
  8,
  'WBTC',
  'Wrapped BTC'
)

export const USDCe_BERACHAIN = new Token(
  ChainId.BERACHAIN,
  '0x549943e04f40284185054145c6E4e9568C1D3241',
  6,
  'USDC.e',
  'USDC.e'
)

export const HONEY_BERACHAIN = new Token(
  ChainId.BERACHAIN,
  '0xFCBD14DC51f0A4d49d5E53C2E0950e0bC26d0Dce',
  18,
  'HONEY',
  'Honey'
)

export const BYUSD_BERACHAIN = new Token(
  ChainId.BERACHAIN,
  '0x688e72142674041f8f6Af4c808a4045cA1D6aC82',
  6,
  'BYUSD',
  'BYUSD'
)

export const USDT0_BERACHAIN = new Token(
  ChainId.BERACHAIN,
  '0x779Ded0c9e1022225f8E0630b35a9b54bE713736',
  6,
  'USDT0',
  'USDT0'
)

export const HOLD_BERACHAIN = new Token(
  ChainId.BERACHAIN,
  '0xFF0a636Dfc44Bb0129b631cDd38D21B613290c98',
  18,
  'HOLD',
  'Holdstation'
)

export const UNI: { [chainId: number]: Token } = {}

export const ARB = new Token(ChainId.ARBITRUM_ONE, '0x912CE59144191C1204E64559FE8253a0e49E6548', 18, 'ARB', 'Arbitrum')

export const LDO = new Token(ChainId.MAINNET, '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32', 18, 'LDO', 'Lido DAO Token')

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  ...(WETH9 as Record<ChainId, Token>),
  [ChainId.ZKSYNC]: new Token(
    ChainId.ZKSYNC,
    '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.BERACHAIN_BARTIO]: new Token(
    ChainId.BERACHAIN_BARTIO,
    '0x7507c1dc16935B82698e4C63f2746A2fCf994dF8',
    18,
    'WBERA',
    'Wrapped Bera'
  ),
  [ChainId.BERACHAIN]: new Token(
    ChainId.BERACHAIN,
    '0x6969696969696969696969696969696969696969',
    18,
    'WBERA',
    'Wrapped Bera'
  ),
}

function isCelo(chainId: number): chainId is ChainId.CELO | ChainId.CELO_ALFAJORES {
  return chainId === ChainId.CELO_ALFAJORES || chainId === ChainId.CELO
}

function getCeloNativeCurrency(chainId: number) {
  switch (chainId) {
    case ChainId.CELO_ALFAJORES:
      return CELO_CELO_ALFAJORES
    case ChainId.CELO:
      return CELO_CELO
    default:
      throw new Error('Not celo')
  }
}

function isPolygon(chainId: number): chainId is ChainId.POLYGON | ChainId.POLYGON_MUMBAI {
  return chainId === ChainId.POLYGON_MUMBAI || chainId === ChainId.POLYGON
}

class PolygonNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isPolygon(this.chainId)) throw new Error('Not Polygon')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isPolygon(chainId)) throw new Error('Not Polygon')
    super(chainId, 18, 'MATIC', 'Matic')
  }
}

function isBsc(chainId: number): chainId is ChainId.BNB {
  return chainId === ChainId.BNB
}

class BscNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isBsc(this.chainId)) throw new Error('Not bnb')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isBsc(chainId)) throw new Error('Not bnb')
    super(chainId, 18, 'BNB', 'BNB')
  }
}

function isAvalanche(chainId: number): chainId is ChainId.AVALANCHE {
  return chainId === ChainId.AVALANCHE
}

class AvaxNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isAvalanche(this.chainId)) throw new Error('Not avalanche')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isAvalanche(chainId)) throw new Error('Not avalanche')
    super(chainId, 18, 'AVAX', 'AVAX')
  }
}

function isBerachain(chainId: number): chainId is ChainId.BERACHAIN {
  return [ChainId.BERACHAIN_BARTIO, ChainId.BERACHAIN].includes(chainId)
}

class BeraNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isBerachain(this.chainId)) throw new Error('Not Berachain')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isBerachain(chainId)) throw new Error('Not Berachain')
    super(chainId, 18, 'BERA', 'Bera')
  }
}

class ExtendedEther extends NativeCurrency {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error(`Unsupported chain ID: ${this.chainId}`)
  }

  protected constructor(chainId: number) {
    super(chainId, 18, 'ETH', 'Ethereum')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }

  public equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {}
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId]
  let nativeCurrency: NativeCurrency | Token
  if (isPolygon(chainId)) {
    nativeCurrency = new PolygonNativeCurrency(chainId)
  } else if (isCelo(chainId)) {
    nativeCurrency = getCeloNativeCurrency(chainId)
  } else if (isBsc(chainId)) {
    nativeCurrency = new BscNativeCurrency(chainId)
  } else if (isAvalanche(chainId)) {
    nativeCurrency = new AvaxNativeCurrency(chainId)
  } else if (isBerachain(chainId)) {
    nativeCurrency = new BeraNativeCurrency(chainId)
  } else {
    nativeCurrency = ExtendedEther.onChain(chainId)
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency)
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in ChainId]?: string } } = {
  USDC: {
    // [ChainId.MAINNET]: USDC_MAINNET.address,
    // [ChainId.ARBITRUM_ONE]: USDC_ARBITRUM.address,
    // [ChainId.ARBITRUM_GOERLI]: USDC_ARBITRUM_GOERLI.address,
    // [ChainId.OPTIMISM]: USDC_OPTIMISM.address,
    // [ChainId.OPTIMISM_GOERLI]: USDC_OPTIMISM_GOERLI.address,
    // [ChainId.POLYGON]: USDC_POLYGON.address,
    // [ChainId.POLYGON_MUMBAI]: USDC_POLYGON_MUMBAI.address,
    // [ChainId.BNB]: USDC_BSC.address,
    // [ChainId.BASE]: USDC_BASE.address,
    // [ChainId.CELO]: USDC_CELO.address,
    // [ChainId.CELO_ALFAJORES]: USDC_CELO.address,
    // [ChainId.GOERLI]: USDC_GOERLI.address,
    // [ChainId.SEPOLIA]: USDC_SEPOLIA.address,
    // [ChainId.AVALANCHE]: USDC_AVALANCHE.address,
    [ChainId.ZKSYNC]: USDCe_ZKSYNC.address,
    [ChainId.BERACHAIN_BARTIO]: tUSDC_BERACHAIN_BARTIO.address,
    [ChainId.BERACHAIN]: HONEY_BERACHAIN.address,
  },
}

const STABLECOINS: { [chainId in ChainId]?: Token[] } = {
  // [ChainId.MAINNET]: [USDC_MAINNET, DAI, USDT],
  // [ChainId.ARBITRUM_ONE]: [USDC_ARBITRUM, DAI_ARBITRUM_ONE],
  // [ChainId.ARBITRUM_GOERLI]: [USDC_ARBITRUM_GOERLI],
  // [ChainId.OPTIMISM]: [USDC_OPTIMISM, DAI_OPTIMISM],
  // [ChainId.OPTIMISM_GOERLI]: [USDC_OPTIMISM_GOERLI],
  // [ChainId.POLYGON]: [USDC_POLYGON, DAI_POLYGON],
  // [ChainId.POLYGON_MUMBAI]: [USDC_POLYGON_MUMBAI],
  // [ChainId.BNB]: [USDC_BSC],
  // [ChainId.BASE]: [USDC_BASE],
  // [ChainId.CELO]: [USDC_CELO],
  // [ChainId.CELO_ALFAJORES]: [USDC_CELO],
  // [ChainId.GOERLI]: [USDC_GOERLI],
  // [ChainId.SEPOLIA]: [USDC_SEPOLIA],
  // [ChainId.AVALANCHE]: [USDC_AVALANCHE],
  // [ChainId.GNOSIS]: [],
  // [ChainId.MOONBEAM]: [],
  // [ChainId.BASE_GOERLI]: [],
  // [ChainId.OPTIMISM_SEPOLIA]: [USDC_SEPOLIA],
  // [ChainId.ARBITRUM_SEPOLIA]: [],
  // [ChainId.ZORA_SEPOLIA]: [],
  // [ChainId.ZORA]: [],
  // [ChainId.ROOTSTOCK]: [],
  // [ChainId.BLAST]: [USDB_BLAST],
  [ChainId.ZKSYNC]: [USDCe_ZKSYNC, USDT_ZKSYNC],
  [ChainId.BERACHAIN_BARTIO]: [tUSDC_BERACHAIN_BARTIO],
  [ChainId.BERACHAIN]: [USDCe_BERACHAIN, HONEY_BERACHAIN, BYUSD_BERACHAIN, USDT0_BERACHAIN],
}

export function isStablecoin(currency?: Currency): boolean {
  if (!currency) return false

  const coins = STABLECOINS[currency.chainId as ChainId]

  return !!coins && coins.some((stablecoin) => stablecoin.equals(currency))
}

export const UNKNOWN_TOKEN_SYMBOL = 'UNKNOWN'
export const UNKNOWN_TOKEN_NAME = 'Unknown Token'
