import { PayMasterItem } from './slices'

interface PaymasterInfo {
  tokens: PayMasterItem[]
  gasPondAddress: string
}

const ZKSYNC_PAYMASTER_INFO: PaymasterInfo = {
  tokens: [
    {
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: '18',
      decimalsToShow: 6,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/ETHUSDT.png',
      isEnable: true,
    },
    {
      address: '0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'HOLD',
      symbol: 'HOLD',
      decimals: '18',
      decimalsToShow: 3,
      imageUrl: 'https://static.holdstation.com/tokens/chain/324/0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2.png',
      isEnable: true,
    },
    {
      address: '0x5fd37f12029511f17c1898477f7fb685cf6c0c0f',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'KZ',
      symbol: 'KZ',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/KZ.png',
      isEnable: true,
    },
    {
      address: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'ZKsync',
      symbol: 'ZK',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/tokens/chain/324/zks.png',
      isEnable: true,
    },
    {
      address: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'USDC',
      symbol: 'USDC',
      decimals: '6',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/usdc_native.png',
      isEnable: true,
    },
    {
      address: '0x10d967f46b06580c4a87b05c78f04e4df25c0db0',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'uGold',
      symbol: 'UGOLD',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/uGold.png',
      isEnable: true,
    },
    {
      address: '0x144B83555D8A3119b0a69a7BC2F0a0388308FeE3',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'AutoAir AI',
      symbol: 'AAI',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/AAI.png',
      isEnable: false,
    },
    {
      address: '0x79db8c67d0c33203da4efb58f7d325e1e0d4d692',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Zeek Coin',
      symbol: 'MEOW',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/meow.png',
      isEnable: true,
    },
    {
      address: '0x244C238325fC1Bdf6EdeD726EE1b47d55895D944',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Zorro',
      symbol: 'ZORRO',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/tokens/chain/324/ZORRO.png',
      isEnable: true,
    },
    {
      address: '0xb0c2bdc425fd01c33d8514f8be016070212bdc6a',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Long Mao',
      symbol: 'LMAO',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://assets.coingecko.com/coins/images/35213/large/rsz_longmaopfpp.png?1707898201',
      isEnable: true,
    },
    {
      address: '0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Mute',
      symbol: 'MUTE',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/tokens/chain/1/0xa49d7499271ae71cd8ab9ac515e6694c755d400c.png',
      isEnable: true,
    },
    {
      address: '0x31c2c031fdc9d33e974f327ab0d9883eae06ca4a',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'zkSwap Finance',
      symbol: 'ZF',
      decimals: '18',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/ZF.png',
      isEnable: true,
    },
    {
      address: '0x703b52f2b28febcb60e1372858af5b18849fe867',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Wrapped stETH',
      symbol: 'WSTETH',
      decimals: '18',
      decimalsToShow: 5,
      imageUrl: 'https://static.holdstation.com/tokens/chain/1/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0.png',
      isEnable: false,
    },
    {
      address: '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Bridged USDC',
      symbol: 'USDC.e',
      decimals: '6',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/tokens/chain/324/0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4.png',
      isEnable: true,
    },
    {
      address: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'Tether',
      symbol: 'USDT',
      decimals: '6',
      decimalsToShow: 2,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/usdt.png',
      isEnable: true,
    },
    {
      address: '0xc6dac3a53d5d6de9d1d05aa6e28b8e9e41722601',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      name: 'LIBERTAS',
      symbol: 'LIBERTAS',
      decimals: '18',
      decimalsToShow: 4,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/libertas.png',
      isEnable: true,
    },
  ],
  gasPondAddress: '0x4081e092F948Cffd946a75e1F556c13c372304bc',
}

const ZKSYNC_SEPOLIA_PAYMASTER_INFO: PaymasterInfo = {
  tokens: [
    {
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x1800c4AD56dC810f21f4Ede44724c9D32e4C0cE7',
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: '18',
      decimalsToShow: 6,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/ETHUSDT.png',
      isEnable: true,
    },
    {
      address: '0x01af5C55dd98842556f5d22e03FC7dcE2aC04c94',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x1800c4AD56dC810f21f4Ede44724c9D32e4C0cE7',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: '6',
      imageUrl: 'https://static.holdstation.com/tokens/chain/324/0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4.png',
      isEnable: true,
      decimalsToShow: 3,
    },
  ],
  gasPondAddress: '0xE151D85eA1Bc32Fb4863a484F590983F11bd688A',
}

const BERA_CARTIO_PAYMASTER_INFO: PaymasterInfo = {
  tokens: [
    {
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      fixedSender: '0x0000000000000000000000000000000000000001',
      weth: '0x0000000000000000000000000000000000000001',
      name: 'Bera',
      symbol: 'BERA',
      decimals: '18',
      decimalsToShow: 6,
      imageUrl: 'https://static.holdstation.com/dex/images/pairs/BERA.png',
      isEnable: true,
    },
  ],
  gasPondAddress: '0x0000000000000000000000000000000000000001',
}

export const PAYMASTER_LIST: Record<number, PaymasterInfo> = {
  324: ZKSYNC_PAYMASTER_INFO,
  300: ZKSYNC_SEPOLIA_PAYMASTER_INFO,
  80000: BERA_CARTIO_PAYMASTER_INFO,
}
