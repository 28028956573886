import { Subsriber } from './SubscriptionManager'
import { ReadyState } from './useWebsocket'

export function makeid(length: number) {
  let result = ''

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0

  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export interface SharedWebSockets {
  [url: string]: WebSocket & {
    isAuthen?: boolean
    id?: string
    ping?: () => void
    intervalId?: any
  }
}

interface Message {
  type: string
  payload: any
}

export const sharedWebSockets: SharedWebSockets = {}
let retryInterval = 1000

export const getWebSocket = (url: string): SharedWebSockets[string] => {
  if (!sharedWebSockets[url]) {
    // const ping = () => {
    //   if (sharedWebSockets[url]) sharedWebSockets[url].send('ping');
    // };

    Subsriber.emit(url, {
      type: 'readyState',
      readyState: ReadyState.CONNECTING,
    })

    const connect = () => {
      sharedWebSockets[url] = new WebSocket(url)

      sharedWebSockets[url].id = makeid(20)
      sharedWebSockets[url].isAuthen = false
      sharedWebSockets[url].onopen = () => {
        console.log('WebSocket connection established.')
        retryInterval = 1000
        Subsriber.emit(url, {
          type: 'readyState',
          readyState: sharedWebSockets[url].readyState,
          isAuthen: sharedWebSockets[url].isAuthen,
        })

        clearInterval(sharedWebSockets[url].intervalId)
        // sharedWebSockets[url].ping = ping;
        // sharedWebSockets[url].intervalId = setInterval(() => {
        //   if (typeof sharedWebSockets[url]?.ping === 'function') {
        //     sharedWebSockets[url].ping();
        //   } else {
        //     clearInterval(sharedWebSockets[url].intervalId);
        //   }
        // }, 15_000);
      }

      sharedWebSockets[url].onmessage = (event) => {
        const data: Message = JSON.parse(event.data)
        if (data?.type === 'authenticate' && data?.payload) {
          sharedWebSockets[url].isAuthen = true
          Subsriber.emit(url, {
            type: 'readyState',
            readyState: sharedWebSockets[url].readyState,
            isAuthen: sharedWebSockets[url].isAuthen,
          })
        }
        if (data?.type && data?.payload) {
          Subsriber.emit(data.type, { ...data })
        } else {
          Subsriber.emit('message', data)
        }
      }

      sharedWebSockets[url].onerror = (event) => {
        console.error('WebSocket error:', event)
        Subsriber.emit(url, { type: 'error', error: event })
      }

      sharedWebSockets[url].onclose = () => {
        sharedWebSockets[url].ping = undefined
        clearInterval(sharedWebSockets[url].intervalId)
        sharedWebSockets[url].isAuthen = false

        Subsriber.emit(url, {
          type: 'readyState',
          readyState: sharedWebSockets[url].readyState,
          isAuthen: sharedWebSockets[url].isAuthen,
        })
        setTimeout(() => {
          connect()
        }, retryInterval)

        retryInterval = Math.min(retryInterval * 2, 30000)
      }
    }
    connect()
  }

  Subsriber.emit(url, {
    type: 'readyState',
    readyState: sharedWebSockets[url].readyState,
    isAuthen: sharedWebSockets[url].isAuthen,
  })
  return sharedWebSockets[url]
}

export const resetWebSockets = (url?: string): void => {
  // eslint-disable-next-line no-prototype-builtins
  if (url && sharedWebSockets.hasOwnProperty(url)) {
    clearInterval(sharedWebSockets[url].intervalId)
    sharedWebSockets[url].ping = undefined
    sharedWebSockets[url].close()
    delete sharedWebSockets[url]
  } else {
    for (const key in sharedWebSockets) {
      // eslint-disable-next-line no-prototype-builtins
      if (sharedWebSockets.hasOwnProperty(key)) {
        clearInterval(sharedWebSockets[key].intervalId)
        sharedWebSockets[key].ping = undefined
        sharedWebSockets[key].close()
        delete sharedWebSockets[key]
      }
    }
  }
}
