import { useWeb3React } from '@web3-react/core'
import hold from 'assets/svg/hold.svg'
import time from 'assets/svg/time.svg'
import BigNumber from 'bignumber.js'
import { LoadingV2 } from 'components/Button/ButtonV3'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BrigeForm } from './bridge'
import { BERA_OFT, ZKSYNC_OFT } from './constant'
import { useBalance, useEstimateGas } from './data'
import { usePrice } from './store'
import { formatDisplay } from './utils'

export function EstimateReceive({ fee }: { fee: number }) {
  const { control } = useFormContext<BrigeForm>()
  const { chainId } = useWeb3React()
  const amount = useWatch({ control, name: 'value' })
  const [fromChainId, to] = useWatch({ control, name: ['from.chainId', 'to'] })

  const { data: balance } = useBalance()

  const isNeedApprove = useMemo(() => {
    if (!amount || new BigNumber(amount).eq(0)) {
      return false
    }

    if (!balance?.[1]) {
      return false
    }

    if (new BigNumber(amount).multipliedBy(1e18).gt(balance[1].toString())) {
      return true
    }

    return false
  }, [amount, balance])

  const { data: gasFee, isLoading, isError } = useEstimateGas({ amount, fee, isNeedApprove })
  const holdPrice = usePrice((state) => state.priceHold)

  const { name, image } = useMemo(() => {
    if (!chainId) {
      return {
        name: '--',
        image: '',
      }
    }

    if (chainId === BERA_OFT.chainId) {
      return {
        name: ZKSYNC_OFT.name,
        image: BERA_OFT.image,
      }
    }

    return {
      name: BERA_OFT.name,
      image: ZKSYNC_OFT.image,
    }
  }, [chainId])

  const amountInUsd = useMemo(() => {
    if (!holdPrice) {
      return '--'
    }
    const amountFl = parseFloat(amount)
    if (isNaN(amountFl)) {
      return '--'
    }

    return `~ $${formatDisplay(amountFl * Number(holdPrice), { decimalToShow: 2 })}`
  }, [amount, holdPrice])
  const { gas } = useMemo(() => {
    if (isLoading || !gasFee || isError) {
      return {
        gas: '--',
      }
    }

    return {
      gas: `${formatDisplay(
        new BigNumber(gasFee.nativeFee.toHexString())
          .plus(new BigNumber(gasFee.gas.toHexString()))
          .dividedBy(1e18)
          .toNumber(),
        {
          decimalToShow: 6,
        }
      )}`,
    }
  }, [gasFee, isError, isLoading])

  const { withHoldingFee, estReceive } = useMemo(() => {
    const amountFl = parseFloat(amount)
    if (isNaN(amountFl)) {
      return {
        withHoldingFee: '--',
        estReceive: amount,
      }
    }

    return {
      withHoldingFee: formatDisplay(amountFl * fee, { decimalToShow: 5 }),
      estReceive: formatDisplay(amountFl - amountFl * fee, { decimalToShow: 5 }),
    }
  }, [amount, fee])

  const formattedAmount = useMemo(() => {
    if (!amount) {
      return '--'
    }

    return formatDisplay(Number(amount), { decimalToShow: 2 })
  }, [amount])

  const isNeedSwitchNetwork = useMemo(() => {
    if (fromChainId !== chainId) {
      return true
    }

    return false
  }, [chainId, fromChainId])

  if (isNeedSwitchNetwork) {
    return <></>
  }

  // Loading
  if (isLoading) {
    return (
      <div className="flex items-center justify-center gap-1 h-[58px]">
        <LoadingV2 loadingColor="white" className="!w-[14px] !h-[14px] [&>div]:w-[14px] [&>div]:h-[14px]" />

        <p className="text-xs text-[#808080]">Loading</p>
      </div>
    )
  }

  if (!amount) {
    return null
  }

  return (
    <div className="p-5 bg-[#141414] rounded-2xl flex flex-col gap-3">
      <div className="flex flex-col gap-4">
        <h2 className="text-white text-base font-medium">Receive on {name}</h2>

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="relative w-10 h-10">
              <img src={hold} className="w-full h-full object-cover" alt="Zksync" width={40} height={40} />

              <div className="absolute w-[18px] h-[18px] right-0 bottom-0 border-[2px] border-black rounded-full overflow-hidden">
                <img src={to.img} className="w-full h-full object-cover" alt="Zksync" width={40} height={40} />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-white text-2xl font-medium">{formattedAmount}</div>
              <div className="text-[#808080] text-xs font-medium leading-[18px]">{amountInUsd}</div>
            </div>
          </div>

          <div className="px-2 h-7 bg-[#1f1f1f] rounded-full flex items-center gap-1.5">
            <div className="w-4 h-4 relative  overflow-hidden">
              <img src={time} alt="time" width={16} height={16} loading="lazy" />
            </div>

            <div className="justify-start items-center gap-1 flex">
              <p className="text-[#808080] text-sm font-normal leading-tight">Est</p>
              <p className="text-white text-sm font-medium leading-tight">4m</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <p className="text-[#b2b2b2] text-sm">Withholding Fee</p>
          <p className="text-white text-sm">{withHoldingFee}</p>
        </div>

        <div className="flex justify-between items-center">
          <p className="text-[#b2b2b2] text-sm">Est. Receive Amount</p>
          <p className="text-white text-sm">{estReceive} HOLD</p>
        </div>
      </div>

      <div className="bg-[#1f1f1f] w-full h-[1px]" />

      <div className="flex justify-between items-center">
        {/* <EstimateGasBridge gasCost={gas} gasError={isError} isDisplay={true} /> */}
        <p className="text-[#b2b2b2] text-sm">Est. Gas</p>

        <div className="pl-3 pr-2 py-1 bg-[#1f1f1f] rounded-full flex items-center gap-1.5">
          <p className="text-white text-base font-medium">{gas}</p>
          <div className="w-4 h-4 flex-none">
            <img src={image} className="w-full h-full object-contain" alt="hold" width={40} height={40} />
          </div>
          {/* <div className="w-4 h-4">
          <img src={dropdown} className="w-full h-full object-cover" alt="dropdown" width={20} height={20} />
        </div> */}
        </div>
      </div>
    </div>
  )
}
