import { useWeb3React } from '@web3-react/core'
import { gasPondAbi } from 'abis/gas-pond'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { PAYMASTER_LIST } from 'state/paymaster/initStore'
import { setPaymasterRateList } from 'state/paymaster/slices'
import { RateErc20 } from 'state/paymaster/type'
import { AppState } from 'state/reducer'

export async function getPricePoolRateAll(props: { provider?: ethers.providers.Web3Provider; chainId: number }) {
  const { provider, chainId } = props
  if (!provider || !PAYMASTER_LIST[chainId]) {
    return undefined
  }
  try {
    const listAddress = PAYMASTER_LIST[chainId].tokens.map((item) => item.address)
    const listCall = listAddress.map((address: string) => {
      const gasIn = `0x${new BigNumber(Math.pow(10, 15)).toString(16)}`

      const gasPondContract = new ethers.Contract(PAYMASTER_LIST[chainId].gasPondAddress, gasPondAbi, provider)
      return gasPondContract
        .getTokenFee(address, gasIn)
        .catch(() => [ethers.BigNumber.from(0), ethers.BigNumber.from(0)])
    })
    const responseList = await Promise.all(listCall)
    const data = responseList.reduce<Record<string, RateErc20>>((res, value, index) => {
      const selectedToken = PAYMASTER_LIST[chainId].tokens.find(
        (x) => listAddress[index].toLowerCase() === x.address.toLowerCase()
      )
      res[listAddress[index].toLowerCase()] = {
        before: new BigNumber(value[1].toHexString())
          .div(Math.pow(10, Number(selectedToken?.decimals ?? 18)))
          .multipliedBy(1000)
          .toNumber(),
        after: new BigNumber(value[0].toHexString())
          .div(Math.pow(10, Number(selectedToken?.decimals ?? 18)))
          .multipliedBy(1000)
          .toNumber(),
      }
      return res
    }, {})

    console.debug('data', data)
    return data
  } catch (error) {
    console.error(`error on : `, error)
    return undefined
  }
}

export function useGetRate() {
  const { provider, chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const rateList = useAppSelector((state: AppState) => state.paymasterStoreReducer.rateList)

  useEffect(() => {
    // if (!provider || rateList || provider.network?.chainId !== ChainId.ZKSYNC) {
    if (!provider || rateList || !chainId) {
      return
    }
    ;(async () => {
      const data = await getPricePoolRateAll({ provider, chainId })
      if (!data) {
        return
      }
      dispatch(setPaymasterRateList(data))
    })()
  }, [chainId, dispatch, provider, rateList])

  return rateList
}
