import { useWeb3React } from '@web3-react/core'
import { NATIVE_ADDRESS } from 'lib/SwapRouter'
import { useQuery } from 'react-query'

export interface TokenResponse {
  amount: string
  balance: string
  contract_address: string
  contract_decimals: number
  contract_name: string
  contract_ticker_symbol: string
  logo_url: string
  native_token: true
  quote: string
  quote_rate: string
  type: string
}

async function fetcher(account?: string, chainId?: number) {
  if (!account || !chainId) {
    return undefined
  }

  const url = `https://api.holdstation.com/api/user-balance/chain/${chainId}/wallet/${account}`

  const response = await fetch(url)

  if (!response.ok) {
    return undefined
  }

  const data = await response.json()
  const tokens = data.data.map((v: any) => {
    if (v.type === 'native') {
      return {
        ...v,
        contract_address: NATIVE_ADDRESS,
      }
    }

    return v
  })

  return tokens
}

export function useGetTokens() {
  const { account, chainId } = useWeb3React()

  const data = useQuery<TokenResponse[]>(['get-account', account, chainId], () => fetcher(account, chainId), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}
