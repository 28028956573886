import { useWeb3React } from '@web3-react/core'
import syncImg from 'assets/svg/synergy.svg'
import BigNumber from 'bignumber.js'
import { Field } from 'components/swap/constants'
import { POINTS_API_URLS } from 'constants/exchange'
import { useMemo } from 'react'
import { useSwapContext } from 'state/swap/hooks'
import { formatDisplay } from '../Chart/format-balance'
import { useEstimate } from './data'

export const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export function SwapEstimatePoint() {
  const {
    derivedSwapInfo: { currencies },
    swapState: { typedValue },
  } = useSwapContext()

  const { chainId } = useWeb3React()

  const supportedPoint = useMemo(() => (chainId ? POINTS_API_URLS[chainId] : undefined), [chainId])

  const token_in = useMemo(() => {
    const input = currencies?.[Field.INPUT]

    if (!input) {
      return {
        address: '',
        decimals: 18,
      }
    }

    if (input.isNative) {
      return {
        address: NATIVE_ADDRESS,
        decimals: 18,
      }
    }

    return {
      address: input.address,
      decimals: input?.decimals ?? 18,
    }
  }, [currencies])

  const token_out = useMemo(() => {
    const output = currencies?.[Field.OUTPUT]

    if (!output) {
      return {
        address: '',
        decimals: 18,
      }
    }

    if (output.isNative) {
      return {
        address: NATIVE_ADDRESS,
        decimals: 18,
      }
    }

    return {
      address: output.address,
      decimals: output?.decimals ?? 18,
    }
  }, [currencies])

  const { data } = useEstimate(chainId, token_in, token_out, typedValue, currencies?.[Field.OUTPUT])

  const estimate = useMemo(() => {
    if (!data) {
      return '--'
    }

    const res = new BigNumber(data.estimate).toNumber()
    return formatDisplay(res, { decimalToShow: 0 })
  }, [data])

  if (!supportedPoint) {
    return null
  }

  return (
    <div className="flex items-center justify-between">
      <p className="text-sm text-[#808080]">Estimated Point Earned</p>

      <div className="flex items-center gap-1">
        <img src={syncImg} className="w-[20px] aspect-square" alt="syncImg" width={28} height={28} loading="lazy" />

        <p className="text-base text-white">{estimate}</p>
      </div>
    </div>
  )
}
