import { ChainId, Percent } from '@uniswap/sdk-core'
import { FlatFeeOptions } from '@uniswap/universal-router-sdk'
import { FeeOptions, toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo, useState } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import isZero from 'utils/isZero'

import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { estimateGasErc20Payment } from '@holdstation/paymaster-helper'
import { useGetTokens } from 'components/AccountDrawer/MiniPortfolio/NewTokens/data'
import { Field } from 'components/swap/constants'
import { ethers } from 'ethers'
import { useGetTransactionDeadline } from 'hooks/useTransactionDeadline'
import { useInitPaymaster } from 'pages/bridge/hook/useInitPaymaster'
import { useAppSelector } from 'state/hooks'
import { AppState } from 'state/reducer'
import { isClassicTrade } from 'state/routing/utils'
import { useSwapContext } from 'state/swap/hooks'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { Provider } from 'zksync-web3'
import { SWAP_ROUTER_ADDRESSES, SWAP_ROUTER_API_URLS } from '../constants/exchange'
import { NATIVE_ADDRESS, SmartRouterTrade } from '../lib/SwapRouter'
import { useTokenContract } from './useContract'
import { useGetRate } from './useGetRateTokens'
import { PermitSignature } from './usePermitAllowance'

interface SwapOptions {
  slippageTolerance: Percent
  permit?: PermitSignature
  feeOptions?: FeeOptions
  flatFeeOptions?: FlatFeeOptions
}

export function useEstimatedGasSwap(trade: SmartRouterTrade | undefined, options: SwapOptions, isAllowance: boolean) {
  const { account, chainId, provider } = useWeb3React()
  const [gasCost, setGasCost] = useState<BigNumber>(BigNumber.from(0))
  const addressPayGas = useAppSelector((state) => state.paymasterStoreReducer.paymasterTokenSelect.address)

  const contract = useTokenContract(trade?.inputAmount.currency.wrapped.address)
  const [gasError, setGasError] = useState(false)
  const getDeadline = useGetTransactionDeadline()

  const spender = (chainId && SWAP_ROUTER_ADDRESSES[chainId]) || undefined

  useEffect(() => {
    ;(async () => {
      if (chainId !== ChainId.ZKSYNC) {
        return
      }

      try {
        if (!spender || !chainId || !provider || !trade || !account) {
          return
        }
        if (!account) throw new Error('missing account')
        const routerApiUrl = SWAP_ROUTER_API_URLS[chainId]

        if (!routerApiUrl) {
          throw new Error('missing SWAP_ROUTER_API')
        }

        let tx: any

        if (isAllowance && contract) {
          tx = await contract?.populateTransaction.approve(spender, MaxUint256, {
            from: account,
          })
        }

        if (!isAllowance) {
          const deadline = await getDeadline()

          const { data, value } = await SmartRouterTrade.encodeRouterTrade(routerApiUrl, trade, account, {
            slippageTolerance: parseFloat(options.slippageTolerance.toSignificant(6)),
            deadline: deadline?.toNumber(),
          })
          tx = {
            from: account,
            to: SWAP_ROUTER_ADDRESSES[chainId],
            data,
            ...(value && !isZero(value) ? { value: toHex(value) } : {}),
          }
        }

        const providerZk = new Provider('https://mainnet.era.zksync.io', { chainId: 324, name: 'zksync' })

        const gas = await estimateGasErc20Payment(
          {
            network: 'mainnet',
            populateTransaction: tx,
          },

          providerZk,
          account as any,
          addressPayGas
        )

        const gasLimit: BigNumber = calculateGasMargin(gas.gasLimit)

        const totalGasCost = gasLimit.mul(gas.gasPrice)
        setGasError(false)

        setGasCost(totalGasCost)
      } catch (error) {
        setGasError(true)
        console.debug('error', error)
      }
    })()
  }, [
    chainId,
    provider,
    trade,
    account,
    getDeadline,
    options.slippageTolerance,
    contract,
    isAllowance,
    spender,
    addressPayGas,
  ])

  return { gasCost, gasError }
}

export function useCheckTokenByGas() {
  const { data } = useGetTokens()
  useInitPaymaster()
  const rateList = useGetRate()

  const {
    derivedSwapInfo: {
      trade: { trade },
      allowedSlippage,
      currencyBalances,
      currencies,
    },
  } = useSwapContext()
  const balanceInput = useMemo(() => {
    return maxAmountSpend(currencyBalances[Field.INPUT])?.toExact()
  }, [currencyBalances])

  const paymasterTokenSelect = useAppSelector((state: AppState) => state.paymasterStoreReducer.paymasterTokenSelect)

  const { gasCost } = useEstimatedGasSwap(
    isClassicTrade(trade) ? trade : undefined,
    {
      slippageTolerance: allowedSlippage,
    },
    false
  )

  const gasCostInUsd = useMemo(() => {
    if (!data) {
      return {
        gasAmount: 0,
      }
    }
    const quote_rate_native = data.find((item) => item.contract_address.toLowerCase() === NATIVE_ADDRESS)?.quote_rate

    if (paymasterTokenSelect.address === NATIVE_ADDRESS) {
      const gas = Number(parseFloat(ethers.utils.formatEther(gasCost)) * Number(quote_rate_native))

      const gasAmount = gas / Number(quote_rate_native)
      return {
        gasAmount,
      }
    }

    if (!rateList) {
      return {
        gasAmount: 0,
      }
    }

    const gasAmount =
      parseFloat(ethers.utils.formatEther(gasCost)) * rateList[paymasterTokenSelect.address.toLowerCase()].after

    return {
      gasAmount,
    }
  }, [data, paymasterTokenSelect.address, rateList, gasCost])

  const isErrorBalanceSwapByGas = useMemo(() => {
    if (currencies.INPUT?.wrapped?.address?.toLowerCase() !== paymasterTokenSelect?.address?.toLowerCase()) {
      return false
    }
    const amount = maxAmountSpend(trade?.inputAmount)?.toExact()

    return Number(balanceInput) < Number(amount) + gasCostInUsd.gasAmount
  }, [
    balanceInput,
    currencies.INPUT?.wrapped?.address,
    gasCostInUsd.gasAmount,
    paymasterTokenSelect?.address,
    trade?.inputAmount,
  ])

  return isErrorBalanceSwapByGas
}
