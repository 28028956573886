import { Currency } from '@uniswap/sdk-core'
import BigNumber from 'bignumber.js'
import { useQuery } from 'react-query'

import { POINTS_API_URLS } from '../../../constants/exchange'

interface PointResponse {
  estimate: string
}

export interface TokenInProps {
  address: string
  decimals: number
}

async function fetchEstimate({ queryKey }: { queryKey: any }) {
  const [, chain_id, amount_in] = queryKey

  const token_in: TokenInProps = queryKey[3]
  const token_out: TokenInProps = queryKey[4]
  const output = queryKey[5]

  const apiUrl = chain_id ? POINTS_API_URLS[chain_id] : undefined

  if (!token_in.address || !token_out.address || !amount_in || !output) {
    return undefined
  }

  const amount = new BigNumber(amount_in).multipliedBy(Math.pow(10, token_in.decimals)).toFixed()

  const url = `${apiUrl}/estimate?action=swap&token_in=${token_in.address}&token_out=${token_out.address}&amount_in=${amount}`

  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return response.json()
}

export function useEstimate(
  chainId: number | undefined,
  token_in: TokenInProps,
  token_out: TokenInProps,
  typedValue: string,
  output?: Currency
) {
  const data = useQuery<PointResponse>({
    queryKey: ['swap-estimate-point', chainId, typedValue, token_in, token_out, output],
    queryFn: fetchEstimate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 60000,
    refetchInterval: 15000,
  })

  return data
}
