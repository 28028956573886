import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  v3CoreFactoryAddress: string
  multicallAddress: string
  // quoterAddress: string
  v3MigratorAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouter02Address?: string
  v1MixedRouteQuoterAddress?: string
}

const DEFAULT_NETWORKS = [ChainId.ZKSYNC]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

export const UNI_ADDRESSES: AddressMap = {}

export const V2_FACTORY_ADDRESSES: AddressMap = {}

export const V2_ROUTER_ADDRESSES: AddressMap = {}

// zkSync v3 addresses
const ZKSYNC_ADDRESSES: ChainAddresses = {
  v3CoreFactoryAddress: '0x1153D1d27A558471eF051c5D2D075d7D07B84A07',
  multicallAddress: '0x0c68a7C72f074d1c45C16d41fa74eEbC6D16a65C',
  // quoterAddress: '0x0000000000000000000000000000000000000000',
  nonfungiblePositionManagerAddress: '0x7FE3975fb0f9A7F78b015806fB8a1E569b014C10',
  tickLensAddress: '0x462901d5f2c72cfc8B7d76bB5F8A8418C6aF491C',
}

// const BERACHAIN_BARTIO_ADDRESSES: ChainAddresses = {
//   v3CoreFactoryAddress: '0x225C6Eab42aF2ac4067323CD467520B727FfB8ED',
//   multicallAddress: '0xE9FF32832D240702314Efa929d6dB18E90f82daF',
//   nonfungiblePositionManagerAddress: '0x11677580d3Be27F83093455cEDdc034e8714DfD8',
//   tickLensAddress: '0xCaca5910586473646F294d8FA5530cA9E8E3fc38',
// }

const BERACHAIN_ADDRESSES: ChainAddresses = {
  v3CoreFactoryAddress: '0xCaca5910586473646F294d8FA5530cA9E8E3fc38',
  multicallAddress: '0x0fc8067d0d37105E5B4AbF778Dbf34501eBfa8a8',
  nonfungiblePositionManagerAddress: '0x7346aF84D25c318a5D233cA43f42673Dc99EaB17',
  tickLensAddress: '0x36c7BB73405CBd5781479248eAB48D0c5e48B0D4',
}

export const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ZKSYNC]: ZKSYNC_ADDRESSES,
  [ChainId.ZKSYNC_SEPOLIA_TESNET]: ZKSYNC_ADDRESSES,
  [ChainId.BERACHAIN_CARTIO]: ZKSYNC_ADDRESSES,
  // [ChainId.BERACHAIN_BARTIO]: BERACHAIN_BARTIO_ADDRESSES,
  [ChainId.BERACHAIN]: BERACHAIN_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].v3CoreFactoryAddress
    return memo
  }, {}),
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const v3MigratorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].v3MigratorAddress
    if (v3MigratorAddress) {
      memo[chainId] = v3MigratorAddress
    }
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = {}
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {}

export const TIMELOCK_ADDRESSES: AddressMap = {}

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

// export const QUOTER_ADDRESSES: AddressMap = {
//   ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
//     memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
//     return memo
//   }, {}),
// }

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}

export const SWAP_ROUTER_02_ADDRESSES = (chainId: number) => {
  if (SUPPORTED_CHAINS.includes(chainId)) {
    const id = chainId as SupportedChainsType
    return CHAIN_TO_ADDRESSES_MAP[id].swapRouter02Address ?? '0x0000000000000000000000000000000000000000'
  }
  return ''
}
