import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import { formatBalance } from 'pages/Pool/all-pool'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { formatDisplay } from 'utils/formatNumbers'
import { unwrappedToken } from 'utils/unwrappedToken'
import { usePoolDetails } from '../datav2'
import { ChartDetail } from './Chart'

function Left() {
  const { id: idFromUrl } = useParams<{ id?: string }>()
  const id = useMemo(() => idFromUrl?.toLowerCase(), [idFromUrl])

  const { data: pools, loading } = usePoolDetails(id ?? '')

  const id1 = pools?.[id ?? '']?.token0?.address ?? ''
  const id2 = pools?.[id ?? '']?.token1?.address ?? ''

  const token0 = useToken(id1, 324)
  const token1 = useToken(id2, 324)

  const currency0 = useMemo(() => (token0 ? unwrappedToken(token0) : undefined), [token0])
  const currency1 = useMemo(() => (token1 ? unwrappedToken(token1) : undefined), [token1])

  const { symbol1, symbol2, tvlToken0, tvlToken1, tvlUSD, volumeUSD, fee, volumeUSDChange, tvlUSDChange } =
    useMemo(() => {
      if (!pools?.[id ?? '']) {
        return {
          symbol1: '--',
          symbol2: '--',
          tvlToken0: '--',
          tvlToken1: '--',
          tvlUSD: '--',
          volumeUSD: '--',
          fee: '--',
          volumeUSDChange: 0,
          tvlUSDChange: 0,
        }
      }

      const pool = pools[id ?? '']

      return {
        symbol1: pool.token0.symbol === 'WETH' ? 'ETH' : pool.token0.symbol,
        symbol2: pool.token1.symbol === 'WETH' ? 'ETH' : pool.token1.symbol,
        tvlToken0: formatBalance(pool.tvlToken0.toString()),
        tvlToken1: formatBalance(pool.tvlToken1.toString()),
        tvlUSD: formatBalance(pool.tvlUSD.toString()),
        volumeUSD: formatBalance(pool.volumeUSD.toString()),
        fee: formatBalance(new BigNumber(pool.volumeUSD).multipliedBy(pool.feeTier / 1000000).toString()),
        volumeUSDChange: pool.volumeUSDChange,
        tvlUSDChange: pool.tvlUSDChange,
      }
    }, [id, pools])

  if (loading) {
    return (
      <div className="rounded-2xl border border-[#141414] p-4 flex flex-col gap-6">
        <Skeleton width="100%" height={32} baseColor="#202020" highlightColor="#444" borderRadius={12} />
        <Skeleton width="100%" height={32} baseColor="#202020" highlightColor="#444" borderRadius={12} />
      </div>
    )
  }

  return (
    <div className="rounded-2xl border border-[#141414] p-4 flex flex-col gap-6">
      <div className="p-4 bg-[#1C1C1C] rounded-2xl flex flex-col gap-3">
        <p>Total Tokens Locked</p>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <CurrencyLogo currency={currency0} size="20px" />

            <p className="text-sm">{symbol1}</p>
          </div>

          <p className="text-sm">{tvlToken0}</p>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <CurrencyLogo currency={currency1} size="20px" />
            <p className="text-sm">{symbol2}</p>
          </div>
          <p className="text-sm">{tvlToken1}</p>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <p>TVL</p>
        <p className="text-2xl">${tvlUSD}</p>
        <p
          className={clsx('text-base', {
            'text-[#EB5252]': tvlUSDChange < 0,
            'text-[#49EB45]': tvlUSDChange > 0,
          })}
        >
          {tvlUSDChange > 0 ? '+' : ''}
          {formatDisplay(tvlUSDChange, { decimalToShow: 2 })}%
        </p>
      </div>

      <div className="flex flex-col gap-1">
        <p>Volume 24h</p>
        <p className="text-2xl">${volumeUSD}</p>
        <p
          className={clsx('text-base', {
            'text-[#EB5252]': volumeUSDChange < 0,
            'text-[#49EB45]': volumeUSDChange > 0,
          })}
        >
          {volumeUSDChange > 0 ? '+' : ''}
          {formatDisplay(volumeUSDChange, { decimalToShow: 2 })}%
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p>24h Fees</p>
        <p className="text-2xl">${fee}</p>
      </div>
    </div>
  )
}

export function PoolOverview() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-[300px_1fr] items-stretch gap-4 mb-10">
      <Left />

      <ChartDetail />
    </div>
  )
}
