import { useQuery } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import gql from 'graphql-tag'
import { useBlocksFromTimestamps, useDeltaTimestamps } from 'pages/Pool/Overview/data'
import { useMemo } from 'react'
import { getApolloClientByChain } from '../Pool/Overview/client'
import { get2DayChange } from './get2DayChange'
import { PoolData, PoolDataResponse, PoolFields } from './type'

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const POOLS_BULK = (block: number | undefined, pools: string[]) => {
  let poolString = `[`
  pools.map((address) => {
    return (poolString += `"${address.toLowerCase()}",`)
  })
  poolString += ']'
  const queryString =
    `
    query pools {
      pools(where: {id_in: ${poolString}},` +
    (block ? `block: {number: ${block}} ,` : ``) +
    ` orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
        id
        feeTier
        liquidity
        sqrtPrice
        tick
        token0 {
            id
            symbol
            name
            decimals
            derivedETH
        }
        token1 {
            id
            symbol
            name
            decimals
            derivedETH
        }
        token0Price
        token1Price
        volumeUSD
        volumeToken0
        volumeToken1
        txCount
        totalValueLockedToken0
        totalValueLockedToken1
        totalValueLockedUSD
      }
      bundles (where: {id: "1"}) {
        ethPriceUSD
      }
    }
    `
  return gql(queryString)
}

export const POOLS_BULK2 = (block: number | undefined, pools: string[]) => {
  let poolString = `[`
  pools.map((address) => {
    return (poolString += `"${address?.toLowerCase()}",`)
  })
  poolString += ']'
  const queryString =
    `
    query pools {
      pools(where: {id_in: ${poolString}},` +
    (block ? `block: {number: ${block}} ,` : ``) +
    ` orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
        id
        volumeUSD
      }
      bundles (where: {id: "1"}) {
        ethPriceUSD
      }
    }
    `
  return gql(queryString)
}

/**
 * Fetch top addresses by volume
 */
export function usePoolDatas(poolAddresses: string[]): {
  loading: boolean
  error: boolean
  data?: {
    [address: string]: PoolData
  }
} {
  const { chainId } = useWeb3React()

  // get blocks from historic timestamps
  const [t24, t48, tWeek] = useDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t24, t48, tWeek])
  const [block24, block48, blockWeek] = blocks ?? []

  const client = useMemo(() => getApolloClientByChain(chainId), [chainId])

  const { loading, error, data } = useQuery<PoolDataResponse>(POOLS_BULK(undefined, poolAddresses), {
    client,
  })

  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<PoolDataResponse>(POOLS_BULK(block24?.number, poolAddresses), { client })
  const {
    loading: loading48,
    error: error48,
    data: data48,
  } = useQuery<PoolDataResponse>(POOLS_BULK(block48?.number, poolAddresses), { client })
  const {
    loading: loadingWeek,
    error: errorWeek,
    data: dataWeek,
  } = useQuery<PoolDataResponse>(POOLS_BULK(blockWeek?.number, poolAddresses), { client })

  const anyError = Boolean(error || error24 || error48 || blockError || errorWeek)
  const anyLoading = Boolean(loading || loading24 || loading48 || loadingWeek)

  // // return early if not all data yet
  // if (anyError || anyLoading) {
  //   return {
  //     loading: anyLoading,
  //     error: anyError,
  //     data: undefined,
  //   }
  // }

  const ethPriceUSD = useMemo(
    () => (data?.bundles?.[0]?.ethPriceUSD ? parseFloat(data?.bundles?.[0]?.ethPriceUSD) : 0),
    [data?.bundles]
  )

  const parsed = useMemo(
    () =>
      data?.pools
        ? data.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
            accum[poolData.id] = poolData
            return accum
          }, {})
        : {},
    [data?.pools]
  )
  const parsed24 = useMemo(
    () =>
      data24?.pools
        ? data24.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
            accum[poolData.id] = poolData
            return accum
          }, {})
        : {},
    [data24?.pools]
  )
  const parsed48 = useMemo(
    () =>
      data48?.pools
        ? data48.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
            accum[poolData.id] = poolData
            return accum
          }, {})
        : {},
    [data48?.pools]
  )
  const parsedWeek = useMemo(
    () =>
      dataWeek?.pools
        ? dataWeek.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
            accum[poolData.id] = poolData
            return accum
          }, {})
        : {},
    [dataWeek?.pools]
  )

  // format data and calculate daily changes
  const formatted = useMemo(() => {
    return poolAddresses.reduce((accum: { [address: string]: PoolData }, address) => {
      const current: PoolFields | undefined = parsed[address]
      const oneDay: PoolFields | undefined = parsed24[address]
      const twoDay: PoolFields | undefined = parsed48[address]
      const week: PoolFields | undefined = parsedWeek[address]

      if (!current) {
        return accum
      }

      const [volumeUSD, volumeUSDChange] =
        current && oneDay && twoDay
          ? get2DayChange(current.volumeUSD, oneDay.volumeUSD, twoDay.volumeUSD)
          : current
          ? [parseFloat(current.volumeUSD), 0]
          : [0, 0]

      const volumeUSDWeek =
        current && week
          ? parseFloat(current.volumeUSD) - parseFloat(week.volumeUSD)
          : current
          ? parseFloat(current.volumeUSD)
          : 0

      // Hotifx: Subtract fees from TVL to correct data while subgraph is fixed.
      /**
       * Note: see issue desribed here https://github.com/Uniswap/v3-subgraph/issues/74
       * During subgraph deploy switch this month we lost logic to fix this accounting.
       * Grafted sync pending fix now.
       */
      // const feePercent = current ? parseFloat(current.feeTier) / 10000 / 100 : 0
      // const tvlAdjust0 = current?.volumeToken0 ? (parseFloat(current.volumeToken0) * feePercent) / 2 : 0
      // const tvlAdjust1 = current?.volumeToken1 ? (parseFloat(current.volumeToken1) * feePercent) / 2 : 0
      // const tvlToken0 = current ? parseFloat(current.totalValueLockedToken0) - tvlAdjust0 : 0
      // const tvlToken1 = current ? parseFloat(current.totalValueLockedToken1) - tvlAdjust1 : 0
      const tvlToken0 = current ? parseFloat(current.totalValueLockedToken0) : 0
      const tvlToken1 = current ? parseFloat(current.totalValueLockedToken1) : 0
      let tvlUSD = current ? parseFloat(current.totalValueLockedUSD) : 0

      const tvlUSDChange =
        current && oneDay
          ? ((parseFloat(current.totalValueLockedUSD) - parseFloat(oneDay.totalValueLockedUSD)) /
              parseFloat(oneDay.totalValueLockedUSD === '0' ? '1' : oneDay.totalValueLockedUSD)) *
            100
          : 0

      // Part of TVL fix
      const tvlUpdated = current
        ? tvlToken0 * parseFloat(current.token0.derivedETH) * ethPriceUSD +
          tvlToken1 * parseFloat(current.token1.derivedETH) * ethPriceUSD
        : undefined
      if (tvlUpdated) {
        tvlUSD = tvlUpdated
      }

      const feeTier = current ? parseInt(current.feeTier) : 0

      if (current) {
        accum[address] = {
          address,
          feeTier,
          liquidity: parseFloat(current.liquidity),
          sqrtPrice: parseFloat(current.sqrtPrice),
          tick: parseFloat(current.tick),
          token0: {
            address: current.token0.id,
            name: current.token0.name,
            symbol: current.token0.symbol,
            decimals: parseInt(current.token0.decimals),
            derivedETH: parseFloat(current.token0.derivedETH),
          },
          token1: {
            address: current.token1.id,
            name: current.token1.name,
            symbol: current.token1.symbol,
            decimals: parseInt(current.token1.decimals),
            derivedETH: parseFloat(current.token1.derivedETH),
          },
          token0Price: parseFloat(current.token0Price),
          token1Price: parseFloat(current.token1Price),
          volumeUSD,
          volumeUSDChange,
          volumeUSDWeek,
          tvlUSD,
          tvlUSDChange,
          tvlToken0,
          tvlToken1,
        }
      }

      return accum
    }, {})
  }, [ethPriceUSD, parsed, parsed24, parsed48, parsedWeek, poolAddresses])

  return {
    loading: anyLoading,
    error: anyError,
    data: formatted,
  }
}

export function usePoolDetails(address: string) {
  const data = usePoolDatas([address])

  return data
}

export function usePoolDatasByVolume(poolAddresses: string): {
  loading: boolean
  error: boolean
  data?: any
} {
  const { chainId } = useWeb3React()

  // get blocks from historic timestamps
  const [t24, t48, tWeek] = useDeltaTimestamps()
  const { blocks } = useBlocksFromTimestamps([t24, t48, tWeek])
  const [block24] = blocks ?? []

  const client = useMemo(() => getApolloClientByChain(chainId), [chainId])

  const { loading, error, data } = useQuery<PoolDataResponse>(POOLS_BULK2(undefined, [poolAddresses]), {
    client,
  })

  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<PoolDataResponse>(POOLS_BULK2(block24?.number, [poolAddresses]), { client })

  const anyError = Boolean(error || error24)
  const anyLoading = Boolean(loading || loading24)

  // // return early if not all data yet
  // if (anyError || anyLoading) {
  //   return {
  //     loading: anyLoading,
  //     error: anyError,
  //     data: undefined,
  //   }
  // }

  const parsed = useMemo(
    () =>
      data?.pools
        ? data.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
            accum[poolData.id] = poolData
            return accum
          }, {})
        : {},
    [data?.pools]
  )
  const parsed24 = useMemo(
    () =>
      data24?.pools
        ? data24.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
            accum[poolData.id] = poolData
            return accum
          }, {})
        : {},
    [data24?.pools]
  )

  // // format data and calculate daily changes
  // const current: PoolFields | undefined = parsed[poolAddresses]
  // if (!current) {
  //   return {
  //     loading: anyLoading,
  //     error: anyError,
  //     data: 0,
  //   }
  // }
  // const oneDay: PoolFields | undefined = parsed24[poolAddresses]
  // const currentChange = parseFloat(current?.volumeUSD) - parseFloat(oneDay?.volumeUSD ?? 0)

  const currentChange = useMemo(() => {
    if (anyLoading) {
      return undefined
    }
    const current: PoolFields | undefined = parsed[poolAddresses]
    if (!current) {
      return 0
    }
    const oneDay: PoolFields | undefined = parsed24[poolAddresses]
    return parseFloat(current?.volumeUSD) - parseFloat(oneDay?.volumeUSD ?? 0)
  }, [anyLoading, parsed, parsed24, poolAddresses])

  return {
    loading: anyLoading,
    error: anyError,
    data: currentChange,
  }
}

export function usePoolDetailsByVolume(address: string) {
  const data = usePoolDatasByVolume(address)

  return data
}
