import { gql, useQuery } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { notEmpty } from 'pages/AllPoolDetail/datav2'
import { useMemo } from 'react'
import { getApolloClientByChain } from '../client'

// const POOL_HIDE: (string | string[])[] = []

const TOP_POOLS = gql`
  query topPools {
    pools(first: 50, orderBy: totalValueLockedUSD, orderDirection: desc, subgraphError: allow) {
      id
    }
  }
`

interface TopPoolsResponse {
  pools: {
    id: string
  }[]
}

/**
 * Fetch top addresses by volume
 */
export function useTopPoolAddresses(): {
  loading: boolean
  error: boolean
  addresses?: string[]
} {
  const { chainId } = useWeb3React()

  const client = useMemo(() => getApolloClientByChain(chainId), [chainId])

  const { loading, error, data } = useQuery<TopPoolsResponse>(TOP_POOLS, {
    client,
    fetchPolicy: 'cache-first',
  })

  const formattedData = useMemo(() => {
    if (data) {
      return data.pools
        .map((p) => {
          return p.id
        })
        .filter(notEmpty)
    } else {
      return undefined
    }
  }, [data])

  return {
    loading,
    error: Boolean(error),
    addresses: formattedData,
  }
}
