import Loading from 'components/Loading/Loading'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { CopyHelper } from 'theme/components'
import { shortenAddress } from 'utilities/src/addresses'
import { getSymbol } from './Transaction'
import { useGetDetailPool } from './data'

export default function Breadcrumbs() {
  const { id: idFromUrl } = useParams<{ id?: string }>()
  const id = useMemo(() => idFromUrl?.toLowerCase(), [idFromUrl])
  const { data: dataPoolDetail, loading } = useGetDetailPool(id)

  const { title } = useMemo(() => {
    if (!dataPoolDetail?.pool?.token0?.symbol || !dataPoolDetail?.pool?.token1?.symbol) {
      return {
        title: '--',
      }
    }

    return {
      title: `${getSymbol(dataPoolDetail?.pool.token0.symbol || '')}/${getSymbol(
        dataPoolDetail?.pool.token1.symbol || ''
      )}`,
    }
  }, [dataPoolDetail])
  return (
    <div>
      <div className="flex items-center gap-1 mb-4">
        <Link to="/pool" className="text-sm text-[#4C4C4C]">
          Pool {'>'}
        </Link>
        <Loading isLoading={loading} width={150} className="rounded-lg">
          <span className="truncate text-sm text-white ">{title}</span>
        </Loading>

        <CopyHelper iconSize={14} iconPosition="right" toCopy={id ?? ''}>
          <span className="text-sm text-[#9B9B9B]">{shortenAddress(id)}</span>
        </CopyHelper>
      </div>
    </div>
  )
}
