import asset from 'assets/svg/asset.svg'
import hold from 'assets/svg/hold.svg'
import BigNumber from 'bignumber.js'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { formatDisplay } from 'utils/formatNumbers'
import { BrigeForm } from './bridge'
import { dataValue, useBalance } from './data'
import { Price } from './price'

const NUMBER_REGEX = new RegExp('^[0-9]*(\\.|,)?([0-9]{0,2})$')

function formatValue(value: string) {
  if (!value) {
    return ''
  }

  if (value.split('.')?.[1]?.length <= 2) {
    return value
  }

  const num = parseFloat(value)
  const result = (Math.floor(num * 100) / 100).toString()

  return result
}

export function Balance() {
  const { control, setValue, setError } = useFormContext<BrigeForm>()

  const [active, value] = useWatch({ control, name: ['active', 'value'] })

  const { data } = useBalance()

  const formattedBalance = useMemo(() => {
    if (!data?.[0]) {
      return '--'
    }
    const amount = new BigNumber(data[0].toString()).dividedBy(1e18).toString()

    return formatDisplay(Number(formatValue(amount)), { decimalToShow: 2 })
  }, [data])

  const handleValue = useCallback(
    (value: number) => {
      return () => {
        setValue('active', value)
        setError('value', { type: '' })

        if (data) {
          const amount = new BigNumber(data?.[0].toHexString() ?? '0').multipliedBy(value)
          setValue('value', (Math.floor(amount.dividedBy(1e18).toNumber() * 100) / 100).toString())
        }
      }
    },
    [data, setError, setValue]
  )

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!NUMBER_REGEX.test(e.target.value)) {
        return undefined
      }

      if (active !== 0) {
        setValue('active', 0)
      }

      let input = e.target.value
      if (input.includes(',')) {
        input = input.replace(',', '.')
      }

      return input
    },
    [active, setValue]
  )

  return (
    <div className="p-5 bg-[#141414] rounded-2xl flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <p className="text-[#808080] text-sm">Amount</p>

        <div className="flex items-center justify-between gap-2">
          <div className="flex-1">
            <Controller
              control={control}
              name="value"
              rules={{
                required: true,
              }}
              render={({ field: { ref, name, value, onChange } }) => {
                return (
                  <input
                    ref={ref}
                    value={formatValue(value)}
                    onChange={async (e) => {
                      const input = handleInput(e)

                      if (typeof input === 'undefined') {
                        return
                      }

                      onChange({
                        ...e,
                        target: {
                          ...e.target,
                          value: input,
                        },
                      })
                    }}
                    name={name}
                    type="text"
                    inputMode="decimal"
                    autoComplete="off"
                    maxLength={21}
                    className="w-full text-white text-2xl font-medium outline-none bg-transparent caret-[#B9A9FB]"
                    placeholder="0"
                  />
                )
              }}
            />
          </div>

          <div className="px-2 py-1 bg-[#191919] rounded-full flex items-center gap-1.5">
            <p className="text-white text-base font-medium">HOLD</p>

            <div className="w-4 h-4 overflow-hidden">
              <img src={hold} alt="hold" width={40} height={40} loading="lazy" className="w-full h-full" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <Price value={value} />

          <div className="flex items-center gap-1">
            <div className="w-3.5 h-3.5 overflow-hidden">
              <img src={asset} alt="asset" width={14} height={14} loading="lazy" className="w-full h-full" />
            </div>

            <p className="text-[#808080] text-xs leading-[18px]">Balance: {formattedBalance} HOLD</p>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        {dataValue.map((item, idx) => {
          return (
            <button
              onClick={handleValue(item.value)}
              key={idx}
              className="flex-1 h-10 px-4 py-2.5 bg-[#1f1f1f] rounded-xl flex justify-center items-center gap-2.5"
            >
              <span className="text-center text-white text-sm">{item.title}</span>
            </button>
          )
        })}
      </div>
    </div>
  )
}
