import { Dispatch, SetStateAction, memo, useEffect, useMemo, useRef } from 'react'
import Modal from 'components/Modal'
import routing_line from 'assets/svg/routing_line.svg'
import routing_line_2 from 'assets/svg/routing_line_2.svg'
import right from 'assets/svg/right.svg'
import { ProtocolResponse, RouteResponse, SwapLineType } from './type'
import { DEFAULT_NATIVE_ADDRESS } from 'wallet/src/constants/chains'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useCurrency } from 'hooks/Tokens'
import clsx from 'clsx'
import close from 'assets/svg/close.svg'
import up from 'assets/svg/up.svg'
import BigNumber from 'bignumber.js'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { InterfaceTrade } from 'state/routing/types'
import './swap.css'
import { formatProtocol, getLower } from './format-protocol'

function ArrowNext() {
  return (
    <div className="hidden md:block py-[3px] translate-y-[calc(100%-12px)]">
      <img src={right} alt="right" />
    </div>
  )
}

function Percentage({ percentage }: { percentage: number }) {
  return (
    <div className="mx-auto md:mx-0 md:translate-y-[calc(100%-12px)]">
      <div className="px-[8px] py-[5px] rounded-full border border-[#DBFF73] h-[22px] w-fit flex items-center bg-[#121212]">
        <p className="text-[12px] leading-[12px] tracking-[-0.24px] text-[#DBFF73]">
          {formatDisplay(percentage, { decimalToShow: 2 })}%
        </p>
      </div>
    </div>
  )
}

function DappRouter({ protocol, address }: { protocol: string; address: string }) {
  const currency = useCurrency(address === DEFAULT_NATIVE_ADDRESS ? 'ETH' : address || 'ETH', 324)
  console.log('%c ...currency', 'background: #003300; color: #009900', currency)

  return (
    <div className="flex flex-col items-center md:translate-y-[calc(100%-12px)]">
      <div className="flex items-center gap-1 bg-[#292929] rounded-full p-1 pr-[6px] w-fit">
        <CurrencyLogo currency={currency} size="14px" />

        <p className="text-xs text-white">{currency?.symbol}</p>
      </div>

      <div className="w-[1px] h-[10px] bg-[#333333]"></div>

      <div className="w-fit border border-[#4C4C4C] rounded-full px-[6px] py-[4px]">
        <p
          className={clsx('text-xs text-[#4C4C4C]', {
            capitalize: !getLower(protocol),
          })}
        >
          {formatProtocol(protocol)}
        </p>
      </div>
    </div>
  )
}

function Line({ item }: { item: SwapLineType }) {
  switch (item.type) {
    case 'PERCENTAGE':
      return <Percentage percentage={item.payload} />

    case 'NEXT':
      return <ArrowNext />

    case 'DAPP_ROUTER':
      return <DappRouter {...item.payload} />

    default:
      return <></>
  }
}

function SwapRoutingModalComponent(props: {
  data: InterfaceTrade & ProtocolResponse
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}) {
  const { data, visible, setVisible } = props

  const animationRef = useRef<HTMLDivElement>(null)

  const line = useMemo(() => {
    const total_line: SwapLineType[][] = []

    let total_amount = new BigNumber(0)

    for (const elm of data.routes) {
      total_amount = total_amount.plus(new BigNumber(elm.amount))
    }

    data.routes.sort((a: any, b: any) => {
      if (new BigNumber(a.amount).gt(new BigNumber(b.amount))) {
        return -1
      }

      return 1
    })

    for (const element of data.routes) {
      const preline: SwapLineType[] = []

      if (data.routes.length > 1) {
        preline.push({
          type: 'PERCENTAGE',
          payload: new BigNumber(element.amount).dividedBy(total_amount).multipliedBy(100).toNumber(),
        })

        preline.push({ type: 'NEXT' })
      }

      for (let idx = 0; idx < element.swaps.length; idx++) {
        const elm = element.swaps[idx]

        if (idx !== 0) {
          preline.push({ type: 'NEXT' })
        }

        preline.push({
          type: 'DAPP_ROUTER',
          payload: {
            protocol: elm.protocol,
            address: elm.from,
          },
        })
      }

      total_line.push(preline)
    }

    return total_line
  }, [data])

  useEffect(() => {
    if (animationRef.current) {
    }
  }, [])

  return (
    <Modal
      styleOverlay={{ alignItems: 'center', padding: '0px 8px' }}
      isOpen={visible}
      onDismiss={() => setVisible((v) => !v)}
      maxWidth={600}
      style={{ borderRadius: 16 }}
    >
      <div className="p-[20px] pb-[90px] w-full">
        <div className="flex items-center justify-between mb-[32px]">
          <p className="text-xl tracking-[-0.32px] text-white">Routing</p>

          <button onClick={() => setVisible((v) => !v)}>
            <img src={close} alt="close button" />
          </button>
        </div>

        <div className="flex items-center justify-between gap-[12px] mb-[8px]">
          <div className="flex items-center justify-center min-w-[84px]">
            <div className="w-fit flex items-center justify-center gap-[4px] px-[8px] py-[4px] bg-white rounded-full">
              <CurrencyLogo currency={data.inputAmount.currency} size="16px" />

              <p className="text-sm tracking-[-0.24px] text-black font-medium">{data.inputAmount.currency.symbol}</p>
            </div>
          </div>

          <div className="flex-1">
            <img src={routing_line} alt="routing line" className="w-full hidden lg:block" />
            <img src={routing_line_2} alt="routing line" className="w-full lg:hidden" />
          </div>

          <div className="flex items-center justify-center min-w-[84px]">
            <div className="w-fit flex items-center justify-center gap-[4px] px-[8px] py-[4px] bg-white rounded-full">
              <CurrencyLogo currency={data.outputAmount.currency} size="16px" />

              <p className="text-sm tracking-[-0.24px] text-black font-medium">{data.outputAmount.currency.symbol}</p>
            </div>
          </div>
        </div>

        {/* line */}
        <div className="flex items-center justify-between mx-[40px]">
          <div className="w-[6px] aspect-square rounded-full bg-white -translate-x-1/2" />

          <div className="w-[12px] aspect-square bg-white rounded-full translate-x-1/2">
            <img src={up} alt="up icon" />
          </div>
        </div>
        <div className={clsx('relative mx-[40px] flex flex-col gap-[70px] md:gap-0')}>
          {line.map((v, i) => {
            return (
              <div
                key={i}
                className={clsx(
                  'relative w-full flex flex-col gap-[32px] md:gap-0 md:flex-row justify-around rounded-b-2xl',
                  {
                    'min-h-[83px]': i !== 0,
                  }
                )}
              >
                <div className="hidden md:block absolute bottom-0 left-0 w-[10px] h-[10px] rounded-bl-2xl border-l border-b border-dashed border-[#4C4C4C]"></div>
                <div className="hidden md:block absolute bottom-0 right-0 w-[10px] h-[10px] rounded-br-2xl border-r border-b border-dashed border-[#4C4C4C]"></div>
                <div
                  className={clsx({
                    'hidden absolute w-[1px] h-[16px] left-0 bottom-0 translate-y-[8px] md:flex flex-col gap-[4px]':
                      i !== line.length - 1,
                    hidden: i === line.length - 1,
                  })}
                >
                  <div className="w-[1px] h-[4px] bg-[#4C4C4C]"></div>
                  <div className="w-[1px] h-[4px] bg-[#4C4C4C]"></div>
                  <div className="w-[1px] h-[4px] bg-[#4C4C4C]"></div>
                </div>

                <div
                  className={clsx({
                    'hidden md:flex absolute w-[1px] h-[16px] right-0 bottom-0 translate-y-[8px] flex-col gap-1':
                      i !== line.length - 1,
                    hidden: i === line.length - 1,
                  })}
                >
                  <div className="w-[1px] h-[4px] bg-[#4C4C4C]"></div>
                  <div className="w-[1px] h-[4px] bg-[#4C4C4C]"></div>
                  <div className="w-[1px] h-[4px] bg-[#4C4C4C]"></div>
                </div>
                <div className="border-animation absolute inset-0 rounded-2xl hidden md:block"></div>

                {v.map((item, idx) => {
                  return <Line key={idx} item={item} />
                })}
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export const SwapRoutingModal = memo(SwapRoutingModalComponent)
