import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { shortenAddress } from 'utilities/src/addresses'
import { formatDisplay } from 'utils/formatNumbers'
import { useGetDetailPool } from './data'
import { useGetDetailTransaction } from './data-transaction'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1 few seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
})

function getType(type: string, amount0: string) {
  if (type === 'Mint') {
    return {
      color: '#fff',
      text: 'Add LP',
    }
  }

  if (Number(amount0) > 0) {
    return {
      color: '#EB5252',
      text: 'Sell',
    }
  } else {
    return {
      color: '#49EB45',
      text: 'Buy',
    }
  }
}

export function getSymbol(symbol: string | undefined) {
  if (symbol === 'WETH') {
    return 'ETH'
  }

  return symbol || ''
}

export function Transaction() {
  const { id: idFromUrl } = useParams<{ id?: string }>()
  const id = useMemo(() => idFromUrl?.toLowerCase(), [idFromUrl])

  const { data: dataPoolDetail } = useGetDetailPool(id)

  const { data } = useGetDetailTransaction(id)

  const dataTransaction = useMemo(() => {
    return [...(data?.mints ?? []), ...(data?.swaps ?? [])].sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  }, [data])

  if (!data) {
    return null
  }

  return (
    <div className="order-1 md:order-0 flex-1">
      <div className="border border-[#171717] rounded-[16px] overflow-x-auto">
        <table className="w-full border-collapse min-w-[800px]">
          <thead>
            <tr className="[&>th]:text-base [&>th]:text-[#4C4C4C] [&>th]:py-[16px] [&>th]:border-b [&>th]:border-b-[#171717] [&>th]:font-normal">
              <th className="pl-[20px] text-left">Time</th>
              <th className="text-left flex items-center gap-x-1">
                {/* <img className="flex-shrink-0" src={sorticon} alt="sort icon" width={24} height={24} loading="lazy" /> */}
                <p>Type </p>
              </th>
              <th className="text-right">USD</th>
              <th className="text-right">{getSymbol(dataPoolDetail?.pool?.token0?.symbol)}</th>
              <th className="text-right">{getSymbol(dataPoolDetail?.pool?.token1?.symbol)}</th>
              <th className="pr-[20px] text-right">Wallet</th>
            </tr>
          </thead>

          <tbody>
            {dataTransaction.map((item, idx) => {
              const infoType = getType(item.__typename, item.amount0)
              return (
                <tr className="[&>td]:text-base [&>td]:py-[20px]" key={`${item.transaction.id}-${idx}`}>
                  <td className="pl-[20px]">{dayjs(Number(item.timestamp) * 1000).fromNow()}</td>
                  <td style={{ color: infoType.color }}>{infoType.text}</td>
                  <td className="text-right">{formatDisplay(Number(item.amountUSD) || 0, { decimalToShow: 2 })}</td>
                  <td className="text-right">
                    {formatDisplay(Number(item.amount0) || 0, {
                      decimalToShow: 5,
                    })}
                  </td>

                  <td className="text-right">
                    {formatDisplay(Number(item.amount1) || 0, {
                      decimalToShow: 5,
                    })}
                  </td>
                  <td className="pr-[20px] text-right">{shortenAddress(item.origin)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
