import { useWeb3React } from '@web3-react/core'
import close from 'assets/svg/closev2.svg'
import { ButtonV3 } from 'components/Button/ButtonV3'
import { ModalCenter } from 'components/Modal/ModalV2'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'
import { setRecentConnectionDisconnected } from 'state/user/reducer'
import { useAccountAbstraction } from './data'
import { useWallet } from './store'

function closeModal() {
  return useWallet.setState({ open: false })
}

export function ModalAa() {
  const open = useWallet((state) => state.open)
  const { data } = useAccountAbstraction()
  const { connector } = useWeb3React()
  const dispatch = useAppDispatch()

  const disconnect = useCallback(() => {
    connector.deactivate?.()
    connector.resetState()
    closeModal()
    dispatch(setRecentConnectionDisconnected())
  }, [connector, dispatch])

  useEffect(() => {
    if (!data) {
      return
    }

    useWallet.setState({ open: true })
  }, [data])
  return (
    <ModalCenter isVisible={open} setIsVisible={() => {}} className="!max-w-[440px] bg-[#121212]">
      <div>
        <div className="flex items-center justify-end">
          <button onClick={closeModal}>
            <img src={close} alt="close" className="w-6 h-6" />
          </button>
        </div>

        <div className="relative flex flex-col gap-6">
          <div className="px-5 py-2 bg-white/10 rounded-[90px] w-fit mx-auto flex justify-center items-center gap-2">
            <div className="bg-white rounded-full px-[6px] flex items-center justify-center gap-2">
              <div className="w-4 h-4 bg-black/60 rounded-full" />

              <p className="text-base font-medium text-black">EOA</p>
            </div>
          </div>

          <h2 className="text-center text-white text-2xl font-medium">
            Smart Wallets (AA wallets) aren&rsquo;t supported on Berachain yet. Please switch to a regular wallet (EOA
            wallets) to use this feature.
          </h2>

          <ButtonV3
            onClick={disconnect}
            className="w-full p-4 bg-white rounded-[90px] flex justify-center items-center gap-2"
          >
            <span className="text-center text-black text-base font-medium">Switch Wallet</span>
          </ButtonV3>
        </div>
      </div>
    </ModalCenter>
  )
}
