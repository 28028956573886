import { useWeb3React } from '@web3-react/core'
import { registryAbi } from 'abis/registry'
import axios from 'axios'
import { Contract, ethers } from 'ethers'
import { useQuery } from 'react-query'
import { Erc20__factory } from 'uniswap/src/abis/types'
import { BERA_OFT, ZKSYNC_OFT } from './constant'
import { estimateNativeFeeBridgeHold } from './utils'

export const dataValue = [
  { title: '25%', value: 0.25 },
  { title: '50%', value: 0.5 },
  { title: '75%', value: 0.75 },
  { title: '100%', value: 1 },
]

const registryAddress = '0x3627ceDF17554B3EaF01Ad4f6efA3e5D54c53142'

async function fetcher(account?: string, chainId?: number, provider?: ethers.providers.Web3Provider) {
  if (!account || !provider || !chainId) {
    return undefined
  }

  let oft = ZKSYNC_OFT.oft
  let hold = ZKSYNC_OFT.hold

  // zk_sepolia
  if (chainId === BERA_OFT.chainId) {
    oft = BERA_OFT.oft
    hold = BERA_OFT.hold
    // eid = ZKSYNC_OFT.eid
  }
  const contract = Erc20__factory.connect(hold, provider)

  const data = await Promise.all([contract.balanceOf(account), contract.allowance(account, oft)])

  return data
}

export function useBalance() {
  const { account } = useWeb3React()
  const { provider, chainId } = useWeb3React()

  const data = useQuery(['balance-token', account, chainId], () => fetcher(account, chainId, provider), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}

async function fetcherNativeFeeBridge(
  fee: number,
  amount: string,
  isNeedApprove: boolean,
  chainId?: number,
  provider?: ethers.providers.Web3Provider
) {
  if (!provider || !chainId || !amount) {
    return {
      nativeFee: ethers.BigNumber.from(0),
      gas: ethers.BigNumber.from(0),
    }
  }

  let oft = ZKSYNC_OFT.oft
  let eid = BERA_OFT.eid
  let hold = ZKSYNC_OFT.hold
  // zk_sepolia
  if (chainId === BERA_OFT.chainId) {
    oft = BERA_OFT.oft
    eid = ZKSYNC_OFT.eid
    hold = BERA_OFT.hold
  }

  const signer = provider.getSigner()

  if (isNeedApprove) {
    const erc20Contract = Erc20__factory.connect(hold, signer)

    const gas = await erc20Contract.estimateGas.approve(oft, ethers.constants.MaxUint256)
    const gasPrice = await provider.getGasPrice()
    return {
      nativeFee: ethers.BigNumber.from(0),
      gas: gas.mul(gasPrice),
    }
  }

  const address = await signer.getAddress()
  const gas = await estimateNativeFeeBridgeHold({
    oftAddress: oft,
    toAddress: address,
    dstEid: eid,
    provider,
    amountHold: amount,
    fee, // fee 0.2/100 -> sau call contract
  })
  return gas
}

export function useEstimateGas({
  fee,
  amount,
  isNeedApprove,
}: {
  fee: number
  amount: string
  isNeedApprove: boolean
}) {
  const { provider, chainId } = useWeb3React()
  const data = useQuery(
    ['gas-bridge', fee, amount, chainId, isNeedApprove],
    () => fetcherNativeFeeBridge(fee, amount, isNeedApprove, chainId, provider),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: 60000,
    }
  )
  return data
}

export async function fetchHoldPrice() {
  const holdUrl = `https://gateway.holdstation.com/services/launchpad/api/token-hold/hold-staking`

  try {
    const response = await axios.get(holdUrl)
    return Number(response?.data?.holdPrice ?? 0)
  } catch (error) {
    return 0
  }
}

export function useHoldPrice() {
  const data = useQuery(['hold-price'], () => fetchHoldPrice(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}

async function fetchAccount(account?: string, chainId?: number, provider?: ethers.providers.Web3Provider) {
  if (!account || !provider || !chainId) {
    return undefined
  }

  const contract = new Contract(registryAddress, registryAbi, provider)
  const data = await contract.isAccount(account)

  return data
}

// eslint-disable-next-line multiline-comment-style
/**
 * Check address
 * */
export function useAccountAbstraction() {
  const { account } = useWeb3React()
  const { provider, chainId } = useWeb3React()

  const data = useQuery(['account-abstraction', account, chainId], () => fetchAccount(account, chainId, provider), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}
