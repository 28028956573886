import { ChainId } from '@uniswap/sdk-core'
import AppJsonRpcProvider from 'rpc/AppJsonRpcProvider'

import ConfiguredJsonRpcProvider from 'rpc/ConfiguredJsonRpcProvider'
// import { CHAIN_IDS_TO_NAMES, SupportedInterfaceChain } from './chains'
import { CHAIN_IDS_TO_NAMES } from './chains'
import { APP_RPC_URLS, SupportedInterfaceChainAndMainnet } from './networks'

function getAppProvider(chainId: SupportedInterfaceChainAndMainnet) {
  return new AppJsonRpcProvider(
    APP_RPC_URLS[chainId].map(
      (url) => new ConfiguredJsonRpcProvider(url, { chainId, name: CHAIN_IDS_TO_NAMES[chainId] })
    )
  )
}

/** These are the only JsonRpcProviders used directly by the interface. */
export const RPC_PROVIDERS = {
  [ChainId.MAINNET]: getAppProvider(ChainId.MAINNET),
  [ChainId.ZKSYNC]: getAppProvider(ChainId.ZKSYNC),
  [ChainId.ZKSYNC_SEPOLIA_TESNET]: getAppProvider(ChainId.ZKSYNC_SEPOLIA_TESNET),
  [ChainId.BERACHAIN_CARTIO]: getAppProvider(ChainId.BERACHAIN_CARTIO),
  // [ChainId.BERACHAIN_BARTIO]: getAppProvider(ChainId.BERACHAIN_BARTIO),
  [ChainId.BERACHAIN]: getAppProvider(ChainId.BERACHAIN),
} satisfies Record<SupportedInterfaceChainAndMainnet, AppJsonRpcProvider>
