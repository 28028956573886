// utils/SubscriptionManager.ts
type Callback<T> = (data: T) => void

class SubscriptionManager<T = any> {
  private listeners: { [event: string]: Callback<T>[] } = {}

  subscribe(event: string, callback: Callback<T>): () => void {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }
    this.listeners[event].push(callback)

    return () => this.unsubscribe(event, callback)
  }

  unsubscribe(event: string, callback: Callback<T>): void {
    if (!this.listeners[event]) return

    this.listeners[event] = this.listeners[event].filter((listener) => listener !== callback)

    if (this.listeners[event].length === 0) {
      delete this.listeners[event]
    }
  }

  unsubscribeAll(event: string): void {
    if (this.listeners[event]) {
      delete this.listeners[event]
    }
  }

  unsubscribeAllListeners(): void {
    this.listeners = {}
  }

  emit(event: string, data: T): void {
    if (!this.listeners[event]) return

    this.listeners[event].forEach((callback) => callback(data))
  }
}

export default SubscriptionManager
export const Subsriber = new SubscriptionManager()
