import { ChainId } from '@uniswap/sdk-core'
import ms from 'ms'
import { darkTheme } from 'theme/colors'

import { DEFAULT_CHAIN_ID, SupportedL1ChainId, SupportedL2ChainId } from './chains'
import { ZKSYNC_LIST } from './lists'

export const AVERAGE_L1_BLOCK_TIME = ms(`12s`)
export const DEFAULT_MS_BEFORE_WARNING = ms(`10m`)

/**
 *
 * @param chainId
 * @returns The approximate whole number of blocks written to the corresponding chainId per Ethereum mainnet epoch.
 */
export function getBlocksPerMainnetEpochForChainId(chainId: number | undefined): number {
  // Average block times were pulled from https://dune.com/jacobdcastro/avg-block-times on 2024-03-14,
  // and corroborated with that chain's documentation/explorer.
  // Blocks per mainnet epoch is computed as `Math.floor(12s / AVG_BLOCK_TIME)` and hard-coded.
  switch (chainId) {
    case ChainId.ZKSYNC:
      return 12
    case ChainId.BERACHAIN:
    case ChainId.BERACHAIN_BARTIO:
      return 3
    default:
      return 1
  }
}

export enum NetworkType {
  L1,
  L2,
}
interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly explorerName: string
  readonly infoLink: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
  readonly color?: string
  readonly backgroundColor?: string
}

interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
  readonly defaultListUrl?: string
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2
  readonly bridge: string
  readonly statusPage?: string
  readonly defaultListUrl: string
}

type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo
} & { readonly [chainId in SupportedL1ChainId]: L1ChainInfo }

const CHAIN_INFO: ChainInfoMap = {
  // [ChainId.MAINNET]: {
  //   networkType: NetworkType.L1,
  //   docs: 'https://docs.uniswap.org/',
  //   explorer: 'https://etherscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/',
  //   label: 'Ethereum',
  //   nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  //   color: darkTheme.chain_1,
  // },
  // [ChainId.ARBITRUM_ONE]: {
  //   networkType: NetworkType.L2,
  //   blockWaitMsBeforeWarning: ms(`10m`),
  //   bridge: 'https://bridge.arbitrum.io/',
  //   docs: 'https://offchainlabs.com/',
  //   explorer: 'https://arbiscan.io/',
  //   infoLink: 'https://info.uniswap.org/#/arbitrum',
  //   label: 'Arbitrum',
  //   defaultListUrl: '',
  //   helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
  //   nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  //   color: darkTheme.chain_42,
  //   backgroundColor: darkTheme.chain_42161_background,
  // },
  //
  [ChainId.ZKSYNC]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms(`25m`),
    // bridge: 'https://portal.zksync.io/bridge/',
    defaultListUrl: ZKSYNC_LIST,
    docs: 'https://docs.zksync.io/',
    explorer: 'https://era.zksync.network',
    explorerName: 'zkScan',
    infoLink: '',
    label: 'ZKsync',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: darkTheme.chain_324,
    backgroundColor: darkTheme.chain_324_background,
  },
  [ChainId.ZKSYNC_SEPOLIA_TESNET]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms(`25m`),
    // bridge: 'https://portal.zksync.io/bridge/',
    defaultListUrl: ZKSYNC_LIST,
    docs: 'https://docs.zksync.io/',
    explorer: 'https://sepolia.explorer.zksync.io/',
    explorerName: 'zkScan Explorer',
    infoLink: '',
    label: 'zkSync Sepolia Testnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: darkTheme.chain_324,
    backgroundColor: darkTheme.chain_324_background,
  },
  [ChainId.BERACHAIN_CARTIO]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms(`25m`),
    // bridge: 'https://portal.zksync.io/bridge/',
    defaultListUrl: ZKSYNC_LIST,
    docs: 'https://docs.zksync.io/',
    explorer: 'https://80000.testnet.routescan.io/',
    explorerName: 'Berascan',
    infoLink: '',
    label: 'Berachain cArtio',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: darkTheme.chain_324,
    backgroundColor: darkTheme.chain_324_background,
  },
  [ChainId.BERACHAIN_BARTIO]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms(`25m`),
    // bridge: 'https://portal.zksync.io/bridge/',
    defaultListUrl: '',
    docs: 'https://docs.berachain.com/',
    explorer: 'https://bartio.beratrail.io',
    explorerName: 'Berachain bArtio Explorer',
    infoLink: '',
    label: 'Berachain bArtio',
    nativeCurrency: { name: 'Ether', symbol: 'BERA', decimals: 18 },
    color: darkTheme.chain_324,
    backgroundColor: darkTheme.chain_324_background,
  },
  [ChainId.BERACHAIN]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms(`25m`),
    // bridge: 'https://portal.zksync.io/bridge/',
    defaultListUrl: '',
    docs: 'https://docs.berachain.com/',
    explorer: 'https://berascan.com',
    explorerName: 'Berascan',
    infoLink: '',
    label: 'Berachain',
    nativeCurrency: { name: 'Berachain', symbol: 'ETH', decimals: 18 },
    color: darkTheme.chain_324,
    backgroundColor: darkTheme.chain_324_background,
  },
} as const

export function getChainInfo(
  chainId: SupportedL1ChainId,
  featureFlags?: Record<ChainId | SupportedL1ChainId | SupportedL2ChainId | number, boolean>
): L1ChainInfo
export function getChainInfo(
  chainId: SupportedL2ChainId,
  featureFlags?: Record<ChainId | SupportedL1ChainId | SupportedL2ChainId | number, boolean>
): L2ChainInfo
export function getChainInfo(
  chainId: ChainId,
  featureFlags?: Record<ChainId | SupportedL1ChainId | SupportedL2ChainId | number, boolean>
): L1ChainInfo | L2ChainInfo
export function getChainInfo(
  chainId: ChainId | SupportedL1ChainId | SupportedL2ChainId | number | undefined,
  featureFlags?: Record<ChainId | SupportedL1ChainId | SupportedL2ChainId | number, boolean>
): L1ChainInfo | L2ChainInfo | undefined

/**
 * Overloaded method for returning ChainInfo given a chainID
 * Return type varies depending on input type:
 * number | undefined -> returns chaininfo | undefined
 * ChainId -> returns L1ChainInfo | L2ChainInfo
 * SupportedL1ChainId -> returns L1ChainInfo
 * SupportedL2ChainId -> returns L2ChainInfo
 */
export function getChainInfo(
  chainId: any,
  featureFlags?: Record<ChainId | SupportedL1ChainId | SupportedL2ChainId | number, boolean>
): any {
  if (featureFlags && chainId in featureFlags) {
    return featureFlags[chainId] ? CHAIN_INFO[chainId] : undefined
  }
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined
  }
  return undefined
}

const MAINNET_INFO = CHAIN_INFO[DEFAULT_CHAIN_ID]
export function getChainInfoOrDefault(chainId: number | undefined, featureFlags?: Record<number, boolean>) {
  return getChainInfo(chainId, featureFlags) ?? MAINNET_INFO
}
