import berachain from 'assets/png/berachain.png'
import bg2 from 'assets/png/bg2.png'
import halftone from 'assets/png/halftone.png'
import right from 'assets/png/right.png'
import bg1 from 'assets/svg/bg1.svg'
import bg3 from 'assets/svg/bg3.svg'
import zkSync from 'assets/svg/zkSync.svg'

export function Background() {
  return (
    <>
      <div className="fixed left-0 top-[10%] lg:top-[20%] -z-0 pointer-events-none">
        <img src={bg1} alt="bg1" className="translate-x-full w-[100px] 2xl:w-auto" />
        <img src={bg2} alt="bg2" className="w-[100px] 2xl:w-auto" />
        <img src={bg3} alt="bg3" className="translate-x-full w-[100px] 2xl:w-auto" />
      </div>

      <div className="fixed right-[10%] top-[10%] lg:top-[15%] -z-0 pointer-events-none">
        <img src={halftone} alt="halftone" className="w-[100px] 2xl:w-auto" />
      </div>

      <div className="fixed right-0 top-1/2 -z-0 pointer-events-none">
        <img src={right} alt="right" className="w-[120px] 2xl:w-[200px]" />
      </div>
    </>
  )
}

export function BackgroundFooter() {
  return (
    <div className="opacity-20 h-[60px] flex items-center justify-center gap-5">
      <img src={zkSync} alt="zkSync" width={103} height={20} loading="lazy" className="w-[102px]" />
      <img src={berachain} alt="berachain" width={239} height={40} loading="lazy" className="w-[120px]" />
    </div>
  )
}
