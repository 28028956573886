import swapWhite from 'assets/svg/swap-white.svg'
import { ButtonV3 } from 'components/Button/ButtonV3'
import { useFormContext, useWatch } from 'react-hook-form'
import { BrigeForm } from './bridge'

export function Chain() {
  const { control } = useFormContext<BrigeForm>()

  const [from, to] = useWatch({ control, name: ['from', 'to'] })
  // const { chainId } = useWeb3React()

  // const switchBridge = useCallback(() => {
  //   if (from.chainId === ZKSYNC_OFT.chainId) {
  //     setValue('from', {
  //       chainId: BERA_OFT.chainId,
  //       name: BERA_OFT.name,
  //       img: bera,
  //     })

  //     setValue('to', {
  //       chainId: ZKSYNC_OFT.chainId,
  //       name: ZKSYNC_OFT.name,
  //       img: Zksync,
  //     })

  //     setValue('value', '')
  //     return
  //   }

  //   setValue('from', {
  //     chainId: ZKSYNC_OFT.chainId,
  //     name: ZKSYNC_OFT.name,
  //     img: Zksync,
  //   })

  //   setValue('to', {
  //     chainId: BERA_OFT.chainId,
  //     name: BERA_OFT.name,
  //     img: bera,
  //   })
  //   setValue('value', '')
  // }, [from.chainId, setValue])

  // useEffect(() => {
  //   async function detectChainId() {
  //     if (chainId === ZKSYNC_OFT.chainId) {
  //       setValue('from', {
  //         chainId: ZKSYNC_OFT.chainId,
  //         name: ZKSYNC_OFT.name,
  //         img: Zksync,
  //       })

  //       setValue('to', {
  //         chainId: BERA_OFT.chainId,
  //         name: BERA_OFT.name,
  //         img: bera,
  //       })
  //       setValue('value', '')
  //       return
  //     }

  //     if (chainId === BERA_OFT.chainId) {
  //       setValue('from', {
  //         chainId: BERA_OFT.chainId,
  //         name: BERA_OFT.name,
  //         img: bera,
  //       })

  //       setValue('to', {
  //         chainId: ZKSYNC_OFT.chainId,
  //         name: ZKSYNC_OFT.name,
  //         img: Zksync,
  //       })

  //       setValue('value', '')
  //       return
  //     }
  //   }

  //   detectChainId()
  // }, [chainId, setValue])

  return (
    <div className="relative bg-[#141414] rounded-2xl flex flex-col lg:flex-row lg:items-center lg:gap-6 overflow-hidden">
      <div className="flex-1 p-4 lg:p-5 bg-[#141414] flex items-center gap-3">
        <div className="w-10 lg:w-11 h-10 lg:h-11 flex-shrink-0">
          <img src={from.img} className="w-full h-full object-cover" alt="Zksync" width={20} height={20} />
        </div>

        <div className="px-0.5 flex flex-col gap-1">
          <p className="text-[#808080] text-sm">From</p>
          <p className="text-white text-base font-medium line-clamp-1">{from.name}</p>
        </div>
      </div>

      <ButtonV3
        disabled
        // onClick={switchBridge}
        className="absolute right-6 lg:right-auto top-1/2 lg:top-auto -translate-y-1/2 lg:translate-y-0 lg:relative  w-9 h-9 p-2 bg-[#191919] rounded-xl border border-[#1f1f1f] flex justify-center items-center gap-2.5"
      >
        <img src={swapWhite} alt="swapWhite" width={20} height={20} loading="lazy" className="rotate-90 lg:rotate-0" />
      </ButtonV3>

      <div className="flex-1 p-4 lg:p-5 bg-[#141414] justify-end items-center gap-3 flex flex-row-reverse lg:flex-row">
        <div className="px-0.5 flex lg:items-end flex-col gap-1">
          <p className="text-[#808080] text-sm">To</p>
          <p className="text-white text-base font-medium line-clamp-1">{to.name}</p>
        </div>

        <div className="w-10 lg:w-11 h-10 lg:h-11 flex-shrink-0">
          <img src={to.img} className="w-full h-full object-cover" alt="bera" width={20} height={20} />
        </div>
      </div>
    </div>
  )
}
